import type { Ref } from "vue";

import { useItems } from "~~/composables/widgets/transaction-history/fenix/useTransactionHistoryFenixItems";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { type ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useTitleControls } from "~~/composables/widgets/items/useTitleControls";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { ColorPickerType } from "~~/models/widgets/widget.core/widget.model";
import { generateFormElementsStylesList } from "~~/assets/utils/widget/form";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { useFormDropdownConfig } from "~~/composables/widgets/form/useFormDropdownConfig";
import { useFormFieldConfig } from "~~/composables/widgets/form/useFormFieldConfig";
import { useFormDatePickerConfig } from "~~/composables/widgets/form/useFormDatepickerConfig";
import { useFormContentConfig } from "~~/composables/widgets/form/useFormContentConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useSimpleButtonControls } from "~~/composables/widgets/common/useSimpleButtonControls";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { CellSizeType } from "~~/models/grid.interface";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useFormElementConfig } from "~~/composables/widgets/form/useFormElementConfig";
import { useTableFieldsConfig } from "~~/composables/widgets/transaction-history/fenix/useTableFieldsConfig";

import { useAllowDisableDependentFieldContentItems } from "../../useAllowDisableDependentFieldContentItems";

const SETTINGS_ITEMS: Record<any, Record<string, any>[]> = {
  [State.TRANSACTION_HISTORY]: [
    {
      label: "Page Title",
      value: "title",
    },
    {
      label: "Transaction Filter",
      value: "filter",
    },
    {
      label: "Table",
      value: "table",
    },
  ],
  [State.BETTING_HISTORY]: [
    {
      label: "Page Title",
      value: "title",
    },
    {
      label: "Transaction Filter",
      value: "filter",
    },
    {
      label: "Table",
      value: "table",
    },
  ],
  [State.PENDING_WITHDRAWALS]: [
    {
      label: "Page Title",
      value: "title",
    },
    {
      label: "Description",
      value: "pending_description",
    },
    {
      label: "Table",
      value: "table",
    },
    {
      label: "Fail Message",
      value: "pending_error_text",
    },
    {
      label: "Cancel Button",
      value: "action_button",
    },
  ],
};

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value);
  const widgetSettingsStore = useWidgetSettingsStore();

  const { DROPDOWN_ITEMS, TABS } = useItems(widget);
  const { selectedWidget, selectedField } = storeToRefs(widgetSettingsStore);

  /*
    Widget
  */

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.page_type,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.transaction_history,
              value: State.TRANSACTION_HISTORY,
            },
            {
              label: STATE_KEY_LABELS.betting_history,
              value: State.BETTING_HISTORY,
            },
            {
              label: STATE_KEY_LABELS.pending_withdrawals,
              value: State.PENDING_WITHDRAWALS,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "page_type", value });
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS[states.value.page_type],
        },
      },
    ];
  });

  /*
    Title
  */

  const titleField = computed<IWidgetField>(() => {
    return fields.value.title.field;
  });

  const pageTitleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: `options.text.${states.value.page_type}`,
        valueSource: fields.value.title.field,
        options: {
          placeholder: "Enter text",
          active: true,
        },
      },
      typography({ source: titleField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: titleField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /*
    Filter Title
  */

  const filterTitleField = computed<IWidgetField>(() => {
    return fields.value.filter_title.field;
  });

  const filterTitleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: filterTitleField.value,
        options: {
          placeholder: "Enter text",
          active: true,
        },
      },
      typography({ source: filterTitleField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: filterTitleField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });
  /*
    Description pending
  */

  const descriptionControls = computed(() => {
    return [
      typography({
        source: fields.value.pending_description.field,
        except: ["link"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.pending_description.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /*
    Filter
  */

  const filterDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.filter.field,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: fields.value.filter.field,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const filterContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Filter"),
        toggleable: true,
        valueSource: fields.value.filter_title.field.options,
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.filter_title.field
          );
        },
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.filter_title.field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Filter Container",
              value: "form",
            },
          ],
        },
      },
    ];
  });

  /*
    Filter container
  */

  const filterContainerDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.form.field,
    });
  });

  const filterContainerContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.displayMode",
            valueSource: fields.value.form.field,
            className: "event-cards-radio border-bottom-none",
            options: {
              label: "Layout",
              items: [
                {
                  icon: "custom:history-layout-1",
                  value: 1,
                },
                {
                  icon: "custom:history-layout-2",
                  value: 2,
                },
                {
                  icon: "custom:history-layout-3",
                  value: 3,
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.distance",
            valueSource: fields.value.form.field,
            options: {
              label: "Distance between elements",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Date filter",
              value: "date_filter",
            },
          ],
        },
      },
      {
        section: generateContentSectionName("Transaction filter"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.transactions_filter.field
          );
        },
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.itemsType",
            valueSource: fields.value.transactions_filter.field,
            className: "group-control-element",
            options: {
              label: "Items type",
              noTextWrap: true,
              items: [
                {
                  label: "Dropdowns",
                  value: "dropdown",
                },
                {
                  label: "Buttons",
                  value: "radio",
                },
              ],
            },
          },
        ],
      },
    ];
  });

  const stylesTabs = computed<ConfigTabItem[]>(() => {
    return generateFormElementsStylesList(widget.value.fields, [
      "title_styling",
    ]);
  });

  const styleDropdownTabs = computed<ConfigTabItem[]>(() => {
    const tabs = [...stylesTabs.value];

    return tabs.filter(
      tab =>
        tab.value !== FormStyleFieldName.RADIO_GROUP &&
        tab.value !== FormStyleFieldName.CHECKBOX
    );
  });

  const styleButtonTabs = computed<ConfigTabItem[]>(() => {
    const tabs = [...stylesTabs.value];

    return tabs.filter(
      tab =>
        tab.value !== FormStyleFieldName.DROPDOWN_MENU &&
        tab.value !== FormStyleFieldName.DROPDOWN_MENU_ITEMS &&
        tab.value !== FormStyleFieldName.DROPDOWNS
    );
  });

  const currentTabs = computed<ConfigTabItem[]>(() => {
    if (
      fields.value.transactions_filter.field.options.itemsType === "dropdown"
    ) {
      return styleDropdownTabs.value;
    }

    return styleButtonTabs.value;
  });

  const filterContainerStyleControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: currentTabs.value,
        },
      },
    ];
  });

  /*
    Transaction filter
  */

  const transactionFilterDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.transactions_filter.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: fields.value.transactions_filter.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: fields.value.transactions_filter.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const transactionFilterContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Width"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: fields.value.transactions_filter.field,
            options: {
              type: ColorPickerType.TEXT,
              exclude: [CellSizeType.HUG],
              icon: "ant-design:column-width-outlined",
              isWidth: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.itemsType",
            valueSource: fields.value.transactions_filter.field,
            className: "group-control-element",
            options: {
              label: "Items type",
              noTextWrap: true,
              items: [
                {
                  label: "Dropdowns",
                  value: "dropdown",
                },
                {
                  label: "Buttons",
                  value: "radio",
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.transactions_filter.field,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Title"),
        toggleable: true,
        valueSource: fields.value.transactions_filter_title.field.options,
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.transactions_filter_title.field
          );
        },
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.transactions_filter_title.field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Types",
              value: fields.value.transaction_types.field.name,
              isActiveValueSource: fields.value.transaction_types.field.options,
            },
            {
              label: "Subtypes",
              value: fields.value.transaction_subtypes.field.name,
              isActiveValueSource:
                fields.value.transaction_subtypes.field.options,
            },
          ],
        },
      },
    ];
  });

  /*
      Date filter
    */

  const dateFilterContentFields = computed<IWidgetField[]>(() => {
    return [fields.value.date_from.field, fields.value.date_to.field];
  });

  const dateFilterDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.date_filter.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: fields.value.date_filter.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: fields.value.date_filter.field,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const dateFilterContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Width"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.width",
            valueSource: fields.value.date_filter.field,
            options: {
              type: ColorPickerType.TEXT,
              exclude: [CellSizeType.HUG],
              icon: "ant-design:column-width-outlined",
              isWidth: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: fields.value.date_filter.field,
            options: {
              label: "Distance between items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Title"),
        toggleable: true,
        valueSource: fields.value.date_filter_title.field.options,
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            selectedWidget.value,
            fields.value.date_filter_title.field
          );
        },
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.date_filter_title.field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigFormItems",
        valuePath: "",
        valueSource: null,
        options: {
          fields: {
            visible: dateFilterContentFields.value,
            hidden: [],
          },
          visibilityPath: "label._active",
          isOrderAllowed: false,
          customEditHandler(field: IWidgetField) {
            widgetSettingsStore.setActiveElement(selectedWidget.value, field);
          },
        },
      },
    ];
  });

  /*
        Date filter title
      */

  const dateFilterTitleField = computed<IWidgetField>(() => {
    return fields.value.date_filter_title.field;
  });

  const { titleControls: dateFilterTitleControls } = useTitleControls(
    {
      field: dateFilterTitleField,
      except: ["ConfigLinkType"],
      label: "",
    },
    emit
  );

  /*
        Transactions filter title
      */

  const transactionsFilterTitleField = computed<IWidgetField>(() => {
    return fields.value.transactions_filter_title.field;
  });

  const { titleControls: transactionsFilterTitleControls } = useTitleControls(
    {
      field: transactionsFilterTitleField,
      except: ["ConfigLinkType"],
      label: "",
    },
    emit
  );

  const tableDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.table.field,
    });
  });

  const eventCardsNoContentControls = computed(() => {
    return [
      ...getInitialDesignList({
        source: fields.value.no_events_title.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      {
        section: generateDesignSectionName("Typography"),
        visible: states.value.items === State.NO_ACTIVE,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: fields.value.no_events_title.field,
            options: {
              placeholder: "Enter text",
              active: true,
            },
          },
          ...(typography({ source: fields.value.no_events_title.field })
            .controls ?? []),
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.no_events_title.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      },
    ];
  });

  const currState = computed<State>(() => {
    return states.value.items as State;
  });

  const stateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: currState.value,
        options: {
          items: [
            {
              label: "There are active operations",
              value: State.ACTIVE_EVENTS,
            },
            {
              label: STATE_KEY_LABELS.no_active,
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "items", value });
        },
      },
    ];
  });

  const tableContentControls = computed(() => {
    if (states.value.items === State.NO_ACTIVE) {
      return [...stateControls.value, ...eventCardsNoContentControls.value];
    }

    return [
      ...stateControls.value,
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.mode",
            valueSource: selectedField.value,
            options: {
              label: "Cards content layout",
              items: [
                {
                  label: "First",
                  value: "table",
                },
                {
                  label: "Second",
                  value: "rows",
                },
                {
                  label: "Third",
                  value: "wrap",
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displayMode.distanceBetweenItems",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between cards items",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.displayMode.distanceBetweenCards",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between cards",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Header card",
              value: "table_header_settings",
              isActiveValueSource:
                fields.value.table_header_settings.field.options,
            },
            {
              label: "Player cards",
              value: "table_row_settings",
            },
          ],
        },
      },
    ];
  });

  /*
    Table header
  */

  const tableHeaderDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.table_header_settings.field,
    });
  });

  const tableHeaderContentControls = computed(() => {
    return [
      typography({
        source: fields.value.table_header_settings.field,
        except: ["link"],
      }),
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Checkboxes",
              value: "table_checkboxes_settings",
            },
          ],
        },
      },
    ];
  });

  /*
    Table rows
  */

  const tableRowsDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.table_row_settings.field,
    });
  });

  const tableRowsContentControls = computed(() => {
    return [
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Data",
              value: "table_data",
            },
            {
              label: "Description",
              value: "table_description",
            },
            {
              label: "Parameters",
              value: "table_parameters",
            },
          ],
        },
      },
    ];
  });

  /*
    Table checkboxes
  */

  const currentCheckboxStatePath = computed<string>(() => {
    if (states.value.table_checkboxes_settings === State.SELECTED_DEFAULT) {
      return "options.check";
    }

    return `options.states.${states.value.table_checkboxes_settings}.check`;
  });

  const tableCheckboxesDesignControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.size",
        valueSource: fields.value.table_checkboxes_settings.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
          isBold: true,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `options.check.fill`,
        valueSource: fields.value.table_checkboxes_settings.field,
        options: {
          placeholder: "None",
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `options.check.border`,
        valueSource: fields.value.table_checkboxes_settings.field,
        options: {
          placeholder: "None",
          label: "Border",
          maxValue: 5,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `options.check.icon`,
        valueSource: fields.value.table_checkboxes_settings.field,
        options: {
          placeholder: "None",
          label: "Check icon",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.table_checkboxes_settings.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
    ];
  });

  const tableCheckboxesContentControls = computed(() => {
    return [
      stateConfig({
        source: states.value.table_checkboxes_settings,
        statesList: [
          {
            label: STATE_KEY_LABELS.selected_default,
            value: State.SELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.selected_hover,
            value: State.SELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.selected_disabled,
            value: State.SELECTED_DISABLED,
          },
          {
            label: STATE_KEY_LABELS.unselected_default,
            value: State.UNSELECTED_DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.unselected_hover,
            value: State.UNSELECTED_HOVER,
          },
          {
            label: STATE_KEY_LABELS.unselected_disabled,
            value: State.UNSELECTED_DISABLED,
          },
        ],
        onUpdate: value => {
          emit("update-state", { state: "table_checkboxes_settings", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.table_checkboxes_settings.field,
        basePath: currentCheckboxStatePath.value,
        except: [
          "ConfigCornerRadius",
          "ConfigSpacingInputs",
          "ConfigBoxShadow",
        ],
      }),
      {
        section: generateDesignSectionName("Check icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentCheckboxStatePath.value}.icon`,
            valueSource: fields.value.table_checkboxes_settings.field,
            options: {
              placeholder: "None",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  /*
    Cancel button
  */

  const selectedElementPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const cancelButtonState = computed(() => {
    return states.value.action_button;
  });

  const { buttonDesignControls: cancelButtonDesignControls } =
    useSimpleButtonControls(cancelButtonState, "action_button", emit);

  const cancelButtonContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.buttonDisplaySettings.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.buttonDisplaySettings.alignment",
        valueSource: selectedField.value,
        className: "border-bottom-none",
        options: {
          isTextContentIcons: false,
        },
      },
      {
        section: generateContentSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "value",
            valueSource: selectedField.value,
            options: {
              placeholder: "Text",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.buttonTextSettings.textColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.buttonTextSettings.theme",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.buttonTextSettings.fontFamily",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.buttonTextSettings.decoration",
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
    ];
  });

  const cancelButtonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", { state: selectedField.value!.name, value });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${selectedElementPath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${selectedElementPath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${selectedElementPath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  /*
    Pending_text_error
  */
  const pendingTextErrorDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      componentPath: "ConfigMarginInputs",
      valuePath: "options.margins",
      valueSource: selectedField.value,
      options: {
        label: "Margin",
        meta: {
          isBold: true,
        },
        isAllSideSetting: true,
        showTooltip: true,
      },
    },
  ]);

  const pendingTextErrorContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
                {
                  label: "Vertical",
                  value: "vertical",
                },
              ],
            },
          },
          selectedField.value?.options.layout === DisplayOrientation.VERTICAL
            ? {
                componentPath: "ConfigAlignment",
                valuePath: "options.horizontalAlignment",
                valueSource: selectedField.value,
                className: "group-control-element--child",
                options: {
                  label: "Alignment",
                  isTextContentIcons: false,
                },
              }
            : {
                componentPath: "ConfigVerticalAlignment",
                valuePath: "options.verticalAlignment",
                valueSource: selectedField.value,
                className: "group-control-element--child",
                options: {
                  label: "Alignment",
                },
              },

          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography(
        {
          source: selectedField.value,
        },
        "Text"
      ),
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.icon",
        valueSource: selectedField.value,
        options: {
          label: "Icon",
          allowDisable: true,
          isStatesTab: false,
        },
      },
    ];
  });

  /*
    Filter elements controls
  */
  const { textInputFieldControls, dropdownFieldControls } =
    useFormContentConfig();

  const {
    formDropdownStyles,
    formDropdownMenuStyles,
    formDropdownMenuItemsDesignStyles,
    formDropdownMenuItemsContentStyles,
    formDropdownMenuItemsStates,
  } = useFormDropdownConfig(widget, states);

  const {
    formLabelsStyles,
    formTooltipDesignStyles,
    formTooltipContentStyles,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    formErrorStyles,
  } = useFormFieldConfig(widget, states);

  const {
    formCalendarDesignStyles,
    formCalendarContentStyles,
    formCalendarStates,
    formDatePickerStyles,
  } = useFormDatePickerConfig(widget, states);

  const {
    checkboxButtonDesignControls,
    checkboxButtonContentControls,
    checkboxButtonStatesControls,

    radioButtonDesignControls,
    radioButtonContentControls,
    radioButtonStatesControls,
  } = useFormElementConfig(widget, states, emit);

  const formStylesControls = computed<Record<string, ControlProp[]>>(() => {
    return {
      "labels_styling": formLabelsStyles.value,
      "tooltips_styling-design": formTooltipDesignStyles.value,
      "tooltips_styling-content": formTooltipContentStyles.value,
      "fields_styling-design": formFieldDesignStyles.value,
      "fields_styling-content": formFieldContentStyles.value,
      "fields_styling-states": formFieldStates.value,
      "errors_styling": formErrorStyles.value,
      "calendar_styling-design": formCalendarDesignStyles.value,
      "calendar_styling-content": formCalendarContentStyles.value,
      "calendar_styling-states": formCalendarStates.value,
      "datepicker_styling": formDatePickerStyles.value,
      "dropdowns_styling": formDropdownStyles.value,
      "dropdown_menu_styling": formDropdownMenuStyles.value,
      "dropdown_menu_items_styling-design":
        formDropdownMenuItemsDesignStyles.value,
      "dropdown_menu_items_styling-content":
        formDropdownMenuItemsContentStyles.value,
      "dropdown_menu_items_styling-states": formDropdownMenuItemsStates.value,

      "radio_group_styling-design": radioButtonDesignControls.value,
      "radio_group_styling-content": radioButtonContentControls.value,
      "radio_group_styling-states": radioButtonStatesControls.value,

      "checkboxes_styling-design": checkboxButtonDesignControls.value,
      "checkboxes_styling-content": checkboxButtonContentControls.value,
      "checkboxes_styling-states": checkboxButtonStatesControls.value,
    };
  });

  const {
    dataDesignControls,
    dataContentControls,
    tableDateControls,
    tableReferenceControls,

    parametrsDesignControls,
    parametrsContentControls,

    tableStatusControls,
    tableTransactionTypeControls,
    tableAmountControls,
    tableDescriptionControls,
  } = useTableFieldsConfig(widget);

  return {
    DROPDOWN_ITEMS,
    TABS,

    widgetDesignControls,
    widgetContentControls,

    pageTitleControls,

    filterTitleControls,

    dateFilterTitleControls,
    transactionsFilterTitleControls,

    descriptionControls,

    filterDesignControls,
    filterContentControls,

    filterContainerDesignControls,
    filterContainerContentControls,
    filterContainerStyleControls,

    dateFilterDesignControls,
    dateFilterContentControls,

    transactionFilterDesignControls,
    transactionFilterContentControls,

    tableDesignControls,
    tableContentControls,

    tableHeaderDesignControls,
    tableHeaderContentControls,
    tableRowsDesignControls,
    tableRowsContentControls,

    tableCheckboxesDesignControls,
    tableCheckboxesContentControls,

    formStylesControls,
    textInputFieldControls,
    dropdownFieldControls,

    cancelButtonStatesControls,
    cancelButtonDesignControls,
    cancelButtonContentControls,

    pendingTextErrorDesignControls,
    pendingTextErrorContentControls,

    dataDesignControls,
    dataContentControls,
    tableDateControls,
    tableReferenceControls,
    parametrsDesignControls,
    parametrsContentControls,

    tableStatusControls,
    tableTransactionTypeControls,
    tableAmountControls,
    tableDescriptionControls,
  };
};
