import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
  ICustomField,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

import { useSportsbookInitData } from "../sportsbook-fenix-mobile/useSportsbookInitData";

export const useSportsBookSwitchConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { switchItemsGroup } = useSportsbookInitData(widget.value);

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField, selectedWidget } = storeToRefs(widgetSettingsStore);

  const switchDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.switch.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.switch.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.switch.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.switch.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: fields.value.switch.field,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const switchField = computed<IWidgetField | undefined>(() =>
    (selectedWidget.value?.fields || []).find(field => field.name === "switch")
  );

  const switchGroupField = computed<ICustomField>(
    () => (switchField.value?.value as ICustomField[])[0]
  );

  const switchContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigArrayItems",
        options: {
          label: "Switch items",
          items: switchGroupField.value.fields,
          "onUpdate:items": (fields: IWidgetField[]) => {
            switchGroupField.value.fields = fields;
          },
          hideCRUD: true,
          hideDND: true,
          optionsClickHandler: () => {
            widgetSettingsStore.setActiveElement(
              selectedWidget.value,
              switchGroupField.value
            );
          },
        },
      },
    ];
  });

  const switchItemsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: switchItemsGroup.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: switchItemsGroup.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: switchItemsGroup.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: switchItemsGroup.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: switchItemsGroup.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const switchItemsContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.resizing",
        valueSource: switchItemsGroup.value,
        className: "group-control-element",
        options: {
          label: "Resizing",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.containerAlignment",
        valueSource: switchItemsGroup.value,
        className: "group-control-element--child",
        visible: switchItemsGroup.value!.options.resizing === ResizingType.HUG,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "content/ConfigNumberInput",
        valuePath: "options.distance",
        valueSource: switchItemsGroup.value,
        options: {
          label: "Distance between tabs",
          suffixContent: "px",
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.color",
            valueSource: switchItemsGroup.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigThemeSelect",
            valuePath: "options.theme",
            valueSource: switchItemsGroup.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
          {
            componentPath: "ConfigFontFamilySelect",
            valuePath: "options.fontFamily",
            valueSource: switchItemsGroup.value,
            options: {
              placeholder: "Select",
              label: "Font family",
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: "options.decoration",
            valueSource: switchItemsGroup.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: switchItemsGroup.value,
            options: {
              label: "Alignment",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconSettings",
            valuePath: "options.itemsIcon",
            valueSource: switchItemsGroup.value,
            options: {
              isStatesTab: false,
              hideSwitch: true,
            },
          },
        ],
      },
    ];
  });

  const statesValuePath = computed<string>(() => {
    if (states.value.switchItemsGroup === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.switchItemsGroup}`;
  });

  const switchItemsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${statesValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${statesValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${statesValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${statesValuePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${statesValuePath.value}.decoration`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Select",
              label: "Decoration",
            },
          },
        ],
      },
    ];
  });

  return {
    switchDesignControls,
    switchContentControls,
    switchItemsDesignControls,
    switchItemsContentControls,
    switchItemsStatesControls,
  };
};
