import { State } from "~~/models/widgets/widget-controls.model";

export const STATE_KEY_LABELS: { [key in State]: string } = {
  [State.DEFAULT]: "Default",
  [State.HOVER]: "Hover",
  [State.ACTIVE]: "Active",
  [State.ACTIVE_CURRENT_PAGE]: "Active (current page)",
  [State.DISABLED]: "Disabled",
  [State.INACTIVE]: "Inactive",
  [State.AUTHORIZED]: "Authorized",
  [State.UNAUTHORIZED]: "Unauthorized",

  [State.FORM_DEFAULT]: "Form",
  [State.FORM_SUCCESS]: "Success page",
  [State.FORM_EXPIRED]: "Expired link page",

  [State.SELECTED_DEFAULT]: "Selected default",
  [State.SELECTED_HOVER]: "Selected hover",
  [State.SELECTED_DISABLED]: "Selected disabled",
  [State.UNSELECTED_DEFAULT]: "Unselected default",
  [State.UNSELECTED_HOVER]: "Unselected hover",
  [State.UNSELECTED_DISABLED]: "Unselected disabled",

  [State.BETSLIP]: "Bet slip",
  [State.BOOKING_CODE]: "Booking code",
  [State.MY_BETS]: "My bets",
  [State.BETS_RESULT]: "Bets result",
  [State.NO_BETS]: "No bets",
  [State.MODAL]: "Modal",
  [State.LOADER]: "Operation processing",

  [State.CASINO_LIST]: "Casino",
  [State.CASINO_SEARCH]: "Search Result",

  [State.ACTIVE_BETS]: "The are bets",
  [State.INACTIVE_BETS]: "The are no bets",

  [State.TRANSACTION_HISTORY]: "Transaction History",
  [State.BETTING_HISTORY]: "Betting History",
  [State.PENDING_WITHDRAWALS]: "Pending withdrawals",

  [State.UP]: "Up",
  [State.DOWN]: "Down",

  [State.SINGLE_BETS]: "Single bets",
  [State.MULTI_BETS]: "Multi bets",
  [State.SYSTEM_BETS]: "System bets",

  [State.NO_ACTIVE]: "No active events",
  [State.ACTIVE_EVENTS]: "There are active events",

  [State.WITH_ACTIVE_EVENTS]: "Sport has active events",
  [State.NO_ACTIVE_EVENTS]: "Sport hasn’t active events",

  [State.PROMO]: "Promo",
  [State.JACKPOT]: "Jackpot",
  [State.NEW]: "New",
  [State.HOT]: "Hot",

  [State.WITH_ACTIVE_GAMES]: "There are game cards",
  [State.NO_ACTIVE_GAMES]: "There are no game cards",

  [State.WITH_FAVORITE_BETS]: "There are favorite bets",
  [State.NO_FAVORITE_BETS]: "There are no favorite bets",

  [State.WITH_POPULAR_LEAGUES]: "There are popular leagues",
  [State.NO_POPULAR_LEAGUES]: "There are no popular leagues",

  [State.SPORTSBOOK_SPORT]: "Sport",
  [State.SPORTSBOOK_COUNTRY]: "Country",
  [State.SPORTSBOOK_LEAGUE]: "League",
  [State.SPORTSBOOK_TOP_LEAGUES]: "Top leagues",
  [State.SPORTSBOOK_ALL_LEAGUES]: "All leagues",
  [State.SPORTSBOOK_TABS_EVENTS]: "Events",
  [State.SPORTSBOOK_TABS_LEAGUE_WINNER]: "League winner",
  [State.SPORTSBOOK_SWITCH_BY_LEAGUE]: "By league",
  [State.SPORTSBOOK_SWITCH_BY_DATE]: "By date",

  [State.PROVIDERS_EXIST]: "There are providers",
  [State.NO_PROVIDERS]: "There are no providers",
  [State.INSUFFICIENT_PERSONAL_DATA]: "Insufficient personal data",

  [State.PROMOTIONS_ALL_GROUPS]: "All Groups",
  [State.PROMOTIONS_ONE_GROUP]: "One Group",

  [State.PROMO_EXIST]: "There are promo group",
  [State.PROMO_NOT_EXIST]: "There are no promo group",

  [State.SMALL_SIZE]: "Small size",
  [State.BIG_SIZE]: "Big size",
  [State.WINNER]: "Winner",

  [State.NOT_RUNNER]: "Not a runner",

  [State.COEFFICIENT_CHANGE]: "Coefficient change",
  [State.FILTERED]: "Filtered",
};
