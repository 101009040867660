import type { Ref } from "vue";

import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import type { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useVideoWidgetConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const widgetSettingsStore = useWidgetSettingsStore();

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = [generateDropdownItem("Video", "video")];

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Video"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.video.field
          );
        },
        controls: [
          {
            componentPath: "ConfigMediaFileUpload",
            valuePath: "value",
            valueSource: fields.value.video.field,
            options: {
              label: "Video",
              fieldName: "video",
              active: true,
              isImage: false,
            },
          },
        ],
      },
    ];
  });

  const videoControls = computed(() => {
    return [
      {
        componentPath: "ConfigMediaFileUpload",
        valuePath: "value",
        valueSource: fields.value.video.field,
        options: {
          active: fields.value.video.field.options._active,
          fieldName: "video",
          isImage: false,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.video.field,
        options: {
          label: "Border",
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.video.field,
        options: {
          label: "Shadow",
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.video.field,
        options: {
          label: "Corner radius",
          isBold: true,
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    videoControls,
  };
};
