<template>
  <ConfigToggle
    v-if="hasContentPermissions"
    size="small"
    :model-value="active"
    :label="label"
    :allow-disable="allowDisable"
    :show-options="showOptions"
    class="widget-control-container p-l-16 p-r-16 p-b-16 p-t-16"
    @update:model-value="handleActive"
    @options-click="$emit('show-options')"
  >
    <ConfigMediaFileUpload
      v-if="active"
      :model-value="modelValue"
      :attribute="attribute"
      :active="active"
      class="p-t-8 config-toggle-image-upload"
      @update:model-value="handleValueUpdate"
    />
  </ConfigToggle>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";

withDefaults(
  defineProps<{
    modelValue?: Record<string, any> | undefined | null;
    label: string;
    active: boolean;
    allowDisable: boolean;
    showOptions: boolean;
    attribute?: string;
  }>(),
  {
    placeholder: "Type value...",
    active: false,
    modelValue: null,
    attribute: "",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
  (e: "update:active", event: boolean): void;
  (e: "show-options"): void;
}>();

const hasContentPermissions = usePermissions().has(
  usePermissions().Permissions.CONTENT
);

const handleActive = (value: boolean): void => {
  emit("update:active", value);
};

const handleValueUpdate = (value: string): void => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/widgets-content-input-container.scss";

.wconfig-text {
  @include widgets-content-input-container;
}

.config-toggle-image-upload {
  .widget-control-container {
    border-bottom: unset;
    padding: 0;
  }
}
</style>
