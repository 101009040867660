import { isObject } from "~~/assets/utils/widget";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import { InnerFieldValue, IPageContentWidget } from "~~/models/page.model";
import {
  ICustomField,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";

export type SourceValue = Record<string, { search: string; change: string }>;

export enum ReplaceMode {
  URL = "url",
  VALUE = "value",
}

const applyUrlChangesToField = (
  sourceValue: SourceValue,
  field: IWidgetField
) => {
  if (field.options.link?.value) {
    field.options.link.value = changeSimilarMultilangValues(
      sourceValue,
      field.options.link.value
    );
  }

  if (field.options.link?.authorizeValue) {
    field.options.link.authorizeValue = changeSimilarMultilangValues(
      sourceValue,
      field.options.link.authorizeValue
    );
  }
};

export const applyContentChangesToField = (
  sourceValue: SourceValue,
  field: IWidgetField
) => {
  if (field.type === WIDGET_FIELD_TYPES.FORM_ELEMENT) {
    const formFields = (field.value as InnerFieldValue)?.fields || {};

    for (const fieldName in formFields) {
      const innerField = formFields[fieldName];

      applyContentChangesToField(sourceValue, innerField as IWidgetField);
    }
    return;
  }

  if (field.value && isObject(field.value)) {
    field.value = changeSimilarMultilangValues(
      sourceValue,
      field.value as Record<string, string>
    );
  }

  if (field.options.label) {
    field.options.label.value = changeSimilarMultilangValues(
      sourceValue,
      field.options.label.value
    );
  }

  if (field.options.placeholder) {
    field.options.placeholder.value = changeSimilarMultilangValues(
      sourceValue,
      field.options.placeholder.value
    );
  }

  if (field.options.tooltip) {
    field.options.tooltip.value = changeSimilarMultilangValues(
      sourceValue,
      field.options.tooltip.value
    );
  }
};

export const applyChangesToFields = (
  sourceValue: SourceValue,
  fields: IWidgetField[],
  replaceMode: ReplaceMode
): void => {
  for (const field of fields) {
    if (field.type === WIDGET_FIELD_TYPES.ARRAY_ELEMENT) {
      const childFields = (field.value as [ICustomField])[0]?.fields;

      applyChangesToFields(
        sourceValue,
        childFields as IWidgetField[],
        replaceMode
      );

      continue;
    }

    if (replaceMode === ReplaceMode.VALUE) {
      applyContentChangesToField(sourceValue, field);
      continue;
    }

    applyUrlChangesToField(sourceValue, field);
  }
};

export const checkStringPresence = (
  rootValue?: string,
  value?: string
): boolean => {
  if (!rootValue || !value) {
    return false;
  }

  if (typeof value !== "string" || typeof rootValue !== "string") {
    return false;
  }

  const rootLowered = rootValue.toLocaleLowerCase().trim();
  const valueLowered = value.toLocaleLowerCase().trim();

  return rootLowered.includes(valueLowered);
};

export const compareMultilangValues = (
  sourceValue: SourceValue,
  value?: Record<string, string>
): boolean => {
  for (const langName in sourceValue) {
    const currLangData = sourceValue[langName];

    if (!currLangData.search) {
      continue;
    }

    if (checkStringPresence(value?.[langName], currLangData.search)) {
      return true;
    }
  }

  return false;
};

export const changeSimilarMultilangValues = (
  sourceValue: SourceValue,
  value: Record<string, string>
): Record<string, any> => {
  for (const langName in sourceValue) {
    const currLangData = sourceValue[langName];

    if (!currLangData.search) {
      continue;
    }

    if (checkStringPresence(value?.[langName], currLangData.search)) {
      value[langName] = value[langName].replace(
        currLangData.search,
        currLangData.change.trim()
      );
    }
  }

  return value;
};

const findFieldUrlSimilarities = (
  sourceValue: SourceValue,
  field: IWidgetField
): boolean => {
  if (compareMultilangValues(sourceValue, field.options.link?.value)) {
    return true;
  }

  if (compareMultilangValues(sourceValue, field.options.link?.authorizeValue)) {
    return true;
  }

  return false;
};

export const findFieldContentSimilarities = (
  sourceValue: SourceValue,
  field: IWidgetField
): boolean => {
  if (field.type === WIDGET_FIELD_TYPES.FORM_ELEMENT) {
    const formFields = (field.value as InnerFieldValue)?.fields || {};

    for (const fieldName in formFields) {
      const innerField = formFields[fieldName];

      if (
        findFieldContentSimilarities(sourceValue, innerField as IWidgetField)
      ) {
        return true;
      }
    }
  }

  if (
    isObject(field.value) &&
    compareMultilangValues(sourceValue, field.value as Record<string, string>)
  ) {
    return true;
  }

  if (compareMultilangValues(sourceValue, field.options.label?.value)) {
    return true;
  }

  if (compareMultilangValues(sourceValue, field.options.placeholder?.value)) {
    return true;
  }

  if (compareMultilangValues(sourceValue, field.options.tooltip?.value)) {
    return true;
  }

  return false;
};

export const findSimilaritiesInFields = (
  sourceValue: SourceValue,
  fields: IWidgetField[],
  replaceMode: ReplaceMode
): boolean => {
  for (const field of fields) {
    if (field.type === WIDGET_FIELD_TYPES.ARRAY_ELEMENT) {
      const childFields = (field.value as [ICustomField])[0]?.fields;

      const hasSimilarities = findSimilaritiesInFields(
        sourceValue,
        childFields as IWidgetField[],
        replaceMode
      );

      if (hasSimilarities) {
        return true;
      }

      continue;
    }

    if (replaceMode === ReplaceMode.VALUE) {
      if (findFieldContentSimilarities(sourceValue, field)) {
        return true;
      }

      continue;
    }

    if (findFieldUrlSimilarities(sourceValue, field)) {
      return true;
    }
  }

  return false;
};

export const applyChangesToBreadcrumbs = <
  T extends IWidgetWithFields | IPageContentWidget
>(
  widget: T,
  sourceValue: SourceValue
): T => {
  if (widget.options.breadcrumbs) {
    for (const breadcrumb of widget.options.breadcrumbs) {
      if (!breadcrumb.link.value) {
        continue;
      }

      breadcrumb.link.value = changeSimilarMultilangValues(
        sourceValue,
        breadcrumb.link.value
      );
    }
  }

  return widget;
};

export const applyChangesToDataParams = <
  T extends IWidgetWithFields | IPageContentWidget
>(
  widget: T,
  sourceValue: SourceValue
): T => {
  if (
    widget.options.bindingParams &&
    Object.keys(widget.options.bindingParams).length
  ) {
    const bindingParams = widget.options.bindingParams;

    for (const dataKey in bindingParams) {
      bindingParams[dataKey].value = changeSimilarMultilangValues(
        sourceValue,
        bindingParams[dataKey].value as Record<string, string>
      );
    }

    widget.options.bindingParams = bindingParams;
  }

  return widget;
};

export const findBreadcrumbsSimilarities = (
  widget: IWidgetWithFields | IPageContentWidget,
  sourceValue: SourceValue
): boolean => {
  if (widget.options.breadcrumbs) {
    for (const breadcrumb of widget.options.breadcrumbs) {
      const hasSimilarBreakcrumb = compareMultilangValues(
        sourceValue,
        breadcrumb.link.value
      );

      if (hasSimilarBreakcrumb) {
        return true;
      }
    }
  }

  return false;
};

export const findDataParamsSimilarities = (
  widget: IWidgetWithFields | IPageContentWidget,
  sourceValue: SourceValue
): boolean => {
  if (
    widget.options.bindingParams &&
    Object.keys(widget.options.bindingParams).length
  ) {
    for (const dataKey in widget.options.bindingParams) {
      const hasSimilarDataParam = compareMultilangValues(
        sourceValue,
        widget.options.bindingParams[dataKey].value as Record<string, string>
      );

      if (hasSimilarDataParam) {
        return true;
      }
    }
  }

  return false;
};
