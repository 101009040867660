<template>
  <div>
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #video>
          <ControlsList
            :widget="widget"
            :controls="videoControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useVideoWidgetConfig } from "~~/composables/widgets/video/useVideoWidgetConfig";

const props = defineProps<{
  widget: IWidgetWithFields;
}>();

const { selectedWidget } = storeToRefs(useWidgetSettingsStore());

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  videoControls,
} = useVideoWidgetConfig(ref(props.widget));
</script>
