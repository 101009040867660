import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { useWidgetsStore } from "~~/store/widgets";
import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";

export const useVideoWidgetInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value);

  const {
    initWidgetOptions,
    initFields,

    addFieldInitialOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([]);

  const init = (): void => {
    initFields();

    addFieldInitialOptions("video", {
      data: {
        value: "",
        options: {
          _active: true,
          border: getDefaultBorderValue(),
          shadow: {
            fill: {
              color: null,
              opacity: 100,
            },
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
          cornerRadius: getDefaultCornerRadiusValue(),
        },
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    init,
  };
};
