import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 105;

const v105WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "SportsBookPreMatchFenixMobileWidget") {
    widget.content.switch = {
      "id": -1701,
      "parent_id": "01939b8e-75f8-7ec5-9dd8-aa75731f36aa",
      "type": "ArrayField",
      "name": "switch",
      "title": "Title",
      "placeholder": "Enter value",
      "default_value": [
        {
          "fields": [
            {
              "type": "TextInputField",
              "name": "by_league",
              "title": "Title",
              "placeholder": "Placeholder",
              "hint": "Hint",
              "default_value": "Default",
              "value": {
                "en-GB": "Default",
              },
              "validation": [
                {
                  "string": {
                    "max": 255,
                  },
                },
              ],
              "options": null,
            },
            {
              "type": "TextInputField",
              "name": "by_date",
              "title": "Title",
              "placeholder": "Placeholder",
              "hint": "Hint",
              "default_value": "Default",
              "value": {
                "en-GB": "Default",
              },
              "validation": [
                {
                  "string": {
                    "max": 255,
                  },
                },
              ],
              "options": null,
            },
          ],
          "options": null,
        },
      ],
      "validation": null,
      "options": {
        "_active": false,
        "fill": {
          "color": "",
          "opacity": 100,
        },
        "border": {
          "fill": {
            "color": "",
            "opacity": 100,
          },
          "stroke": {
            "width": 1,
            "type": "dependent",
          },
          "style": "solid",
        },
        "shadow": {
          "fill": {
            "color": null,
            "opacity": 100,
          },
          "x": 0,
          "y": 0,
          "blur": 0,
          "spread": 0,
        },
        "cornerRadius": {
          "type": "dependent",
          "value": 0,
        },
        "spacing": {
          "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0,
          },
          "margin": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0,
          },
        },
      },
      "value": [
        {
          "id": -1707,
          "parent_id": "01939b8e-75f8-7ec5-9dd8-aa75731f36aa",
          "type": "",
          "name": "switchItemsGroup",
          "title": "",
          "placeholder": "Enter value",
          "default_value": "",
          "validation": {},
          "options": {
            "fill": {
              "color": "#ffffff",
              "opacity": 100,
            },
            "border": {
              "fill": {
                "color": "",
                "opacity": 100,
              },
              "stroke": {
                "width": 1,
                "type": "dependent",
              },
              "style": "solid",
            },
            "shadow": {
              "fill": {
                "color": null,
                "opacity": 100,
              },
              "x": 0,
              "y": 0,
              "blur": 0,
              "spread": 0,
            },
            "cornerRadius": {
              "type": "dependent",
              "value": 0,
            },
            "spacing": {
              "padding": {
                "top": 8,
                "right": 8,
                "bottom": 8,
                "left": 8,
              },
              "margin": {
                "top": 0,
                "right": 0,
                "bottom": 16,
                "left": 0,
              },
            },
            "resizing": "fill",
            "containerAlignment": "left",
            "distance": 16,
            "color": {
              "color": "#595959",
              "opacity": 100,
            },
            "theme": 13,
            "fontFamily": "var(--feco-font-family-1, Roboto)",
            "decoration": ["bold"],
            "alignment": "center",
            "itemsIcon": {
              "textColor": {
                "color": "#000000",
                "opacity": 100,
              },
              "iconSize": 20,
              "position": "left",
              "gap": 8,
            },
            "states": {
              "hover": {
                "fill": {
                  "color": "",
                  "opacity": 100,
                },
                "border": {
                  "fill": {
                    "color": "",
                    "opacity": 100,
                  },
                  "stroke": {
                    "width": 1,
                    "type": "dependent",
                  },
                  "style": "solid",
                },
                "shadow": {
                  "fill": {
                    "color": "",
                    "opacity": 100,
                  },
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0,
                },
                "color": {
                  "color": "",
                  "opacity": 100,
                },
                "decoration": [],
              },
              "active": {
                "fill": {
                  "color": "",
                  "opacity": 100,
                },
                "border": {
                  "fill": {
                    "color": "",
                    "opacity": 100,
                  },
                  "stroke": {
                    "width": 1,
                    "type": "dependent",
                  },
                  "style": "solid",
                },
                "shadow": {
                  "fill": {
                    "color": "",
                    "opacity": 100,
                  },
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0,
                },
                "color": {
                  "color": "",
                  "opacity": 100,
                },
                "decoration": [],
              },
              "disabled": {
                "fill": {
                  "color": "",
                  "opacity": 100,
                },
                "border": {
                  "fill": {
                    "color": "",
                    "opacity": 100,
                  },
                  "stroke": {
                    "width": 1,
                    "type": "dependent",
                  },
                  "style": "solid",
                },
                "shadow": {
                  "fill": {
                    "color": "",
                    "opacity": 100,
                  },
                  "x": 0,
                  "y": 0,
                  "blur": 0,
                  "spread": 0,
                },
                "color": {
                  "color": "",
                  "opacity": 100,
                },
                "decoration": [],
              },
            },
          },
          "value": {
            "en-GB": "",
          },
          "fields": [
            {
              "id": -1708,
              "parent_id": "01939b8e-75f8-7ec5-9dd8-aa75731f36aa",
              "type": "TextInputField",
              "name": "byDate",
              "title": "By Date",
              "placeholder": "Enter value",
              "default_value": "By Date",
              "validation": {},
              "options": {
                "_active": true,
                "text": {
                  "_active": true,
                },
                "icon": {
                  "_active": false,
                  "value": "ant-design:arrow-right-outlined",
                },
                "fill": {
                  "color": "#000000",
                  "opacity": 100,
                },
              },
              "value": {
                "en-GB": "By Date",
              },
            },
            {
              "id": -1709,
              "parent_id": "01939b8e-75f8-7ec5-9dd8-aa75731f36aa",
              "type": "TextInputField",
              "name": "byLeague",
              "title": "By League",
              "placeholder": "Enter value",
              "default_value": "By League",
              "validation": {},
              "options": {
                "_active": true,
                "text": {
                  "_active": true,
                },
                "icon": {
                  "_active": false,
                  "value": "ant-design:arrow-right-outlined",
                },
                "fill": {
                  "color": "#000000",
                  "opacity": 100,
                },
              },
              "value": {
                "en-GB": "By League",
              },
            },
          ],
        },
      ],
    };
  }

  return widget;
};
export default v105WidgetMigration;
