<template>
  <div
    class="config-media-file-uploader p-l-16 p-r-16 p-t-16 p-b-16 widget-control-container"
  >
    <a-upload-dragger
      v-model:file-list="fileList"
      :class="{ 'config-media-file-uploader__upload--error': isUploadingError }"
      name="file"
      :custom-request="handleFileUpload"
      :before-upload="beforeUpload"
      :multiple="false"
      :max-count="1"
    >
      <div v-if="value && fileSrc">
        <component
          :is="isImage ? 'img' : 'video'"
          :src="fileSrc"
          class="config-media-file-uploader__preview-image"
          v-bind="{ type: !isImage ? 'video/mp4' : undefined }"
        >
          <template v-if="!isImage && fileSrc">
            <source :src="fileSrc" />
          </template>
        </component>
        <div class="config-media-file-uploader__preview-image-controls">
          <CommonIcon
            name="ant-design:reload-outlined"
            class="config-media-file-uploader__icon"
          />
          <CommonIcon
            name="ant-design:delete-outlined"
            class="config-media-file-uploader__icon"
            @click.stop="handleFileRemove"
          />
        </div>
      </div>

      <div v-else>
        <p class="f-base">{{ draggerText }}</p>
        <p class="f-small config-media-file-uploader__hint">{{ sizeHint }}</p>
        <p class="f-small config-media-file-uploader__hint">{{ formatHint }}</p>
      </div>
    </a-upload-dragger>
    <p
      v-if="errorMessage"
      class="f-base config-media-file-uploader__error-message"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { UploadRequestOption } from "ant-design-vue/lib/vc-upload/interface";

import { getCurrentField } from "~~/composables/widgets/useGetCurrentField";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import useImageCdnChange from "~~/composables/useImageCdnChange";

const props = withDefaults(
  defineProps<{
    value: string | undefined;
    uploadFunction: (file: File) => Promise<string>;
    isImage: boolean;
    maxFileSize: number;
    formatText: string;
    draggerText: string;
    validAcceptedFormats: Array<string>;
    acceptedFormats: Array<string>;
    fieldName?: string;
    meta?: {
      isContentFile?: boolean;
      isValueOption?: boolean;
    };
  }>(),
  {
    meta: () => ({
      isContentFile: false,
    }),
    fieldName: "",
  }
);

const emit = defineEmits<{
  (e: "update:value", event: string | undefined): void;
}>();

const errorMessage = ref<string>("");
const isUploadingError = ref<boolean>(false);
const fileList = ref<File[] | []>([]);

const { getLocalizedValue } = useLocalizedValue();

const currentField = computed<IWidgetField | undefined>(() => {
  return getCurrentField(props.fieldName).value;
});

const formatHint = computed<string>(
  () =>
    props.formatText || `Accepted formats: ${props.acceptedFormats.join(", ")}`
);

const sizeHint = computed<string>(() =>
  props.isImage
    ? `Image must be smaller than ${props.maxFileSize / 1024 / 1024} MB`
    : `Video must be smaller than ${props.maxFileSize / 1024 / 1024} MB`
);

const fileSrc = computed<string>(() => {
  if (props.meta.isContentFile) {
    const localizedValue = getLocalizedValue.value(currentField.value?.value);

    if (currentField.value && localizedValue) {
      return useImageCdnChange(localizedValue);
    }
    if (currentField.value && !localizedValue) {
      return "";
    }
  }

  if (!props.value || typeof props.value === "object") return "";

  return useImageCdnChange(props.value);
});

const beforeUpload = (file: File): boolean => {
  const isValidFormat = props.validAcceptedFormats.includes(file.type);

  if (!isValidFormat) {
    errorMessage.value = `You can only upload files with the following formats: ${props.acceptedFormats.join(
      ", "
    )}`;
    isUploadingError.value = true;
    return false;
  }

  const isValidFileSize = file.size <= props.maxFileSize;

  if (!isValidFileSize) {
    errorMessage.value = `File must be smaller than ${
      props.maxFileSize / 1024 / 1024
    } MB`;
    isUploadingError.value = true;
    return false;
  }

  errorMessage.value = "";
  isUploadingError.value = false;
  return true;
};

const handleFileUpload = (e: UploadRequestOption): void => {
  const file = e.file;
  props.uploadFunction(file as File).then((resp: string) => {
    handleUpdate(resp);
  });
};

const handleFileRemove = (): void => {
  handleUpdate(undefined);
};

const handleUpdate = (value: string | undefined) => {
  emit("update:value", value);
};
</script>

<style lang="scss">
.config-media-file-uploader {
  padding: $space-m;

  &__preview-image-controls {
    @include flex(center, center, 8px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__preview-image-controls:hover {
    opacity: 1;
  }

  &__icon {
    color: rgba(255, 255, 255, 0.65);
    font-size: 20px;
    margin: 6px;
  }

  &__preview-image:hover + &__preview-image-controls {
    @include flex(center, center, 8px);
  }

  &__preview-image {
    max-width: 200px;
    max-height: 88px;
    border-radius: 6px;
  }

  &__error-message {
    color: $c-icon-font-red;
  }

  &__upload {
    &--error {
      border-color: $c-icon-font-red !important;
    }
  }

  &__hint {
    color: $c-grey-45;
  }

  .ant-upload-list {
    display: none;
  }

  .ant-upload {
    padding: 0 $space-m;
    max-height: 120px;
  }
}
</style>
