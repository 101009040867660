import { isObject } from "~~/assets/utils/widget";

type DiffType = {
  dataBefore: Record<string, any>;
  dataAfter: Record<string, any>;
};

const excludePatterns: RegExp[] = [/.*cssClass.*/i, /.*_styling.*/i];

function compareArrays(arr1: any[], arr2: any[], path: string): DiffType {
  const dataBefore: DiffType["dataBefore"] = {};
  const dataAfter: DiffType["dataAfter"] = {};

  arr1.forEach((item, index) => {
    if (arr2[index] !== undefined) {
      const nestedResult = compareObjects(
        item,
        arr2[index],
        `${path}[${index}]`
      );
      Object.assign(dataBefore, nestedResult.dataBefore);
      Object.assign(dataAfter, nestedResult.dataAfter);
    } else {
      dataBefore[`${path}[${index}]`] = item;
    }
  });

  arr2.forEach((item, index) => {
    if (arr1[index] === undefined) {
      dataAfter[`${path}[${index}]`] = item;
    }
  });

  return { dataBefore, dataAfter };
}

export function compareObjects(
  obj1: any,
  obj2: any,
  path: string = ""
): DiffType {
  const dataBefore: DiffType["dataBefore"] = {};
  const dataAfter: DiffType["dataAfter"] = {};

  const keys = new Set([
    ...Object.keys(obj1 || {}),
    ...Object.keys(obj2 || {}),
  ]);

  keys.forEach(key => {
    const fullPath = path ? `${path}.${key}` : key;

    if (excludePatterns.some(pattern => pattern.test(key))) {
      return;
    }

    const value1 = obj1?.[key];
    const value2 = obj2?.[key];

    if (Array.isArray(value1) && Array.isArray(value2)) {
      const nestedResult = compareArrays(value1, value2, fullPath);

      Object.assign(dataBefore, nestedResult.dataBefore);
      Object.assign(dataAfter, nestedResult.dataAfter);
    } else if (isObject(value1) && isObject(value2)) {
      const nestedResult = compareObjects(value1, value2, fullPath);

      Object.assign(dataBefore, nestedResult.dataBefore);
      Object.assign(dataAfter, nestedResult.dataAfter);
    } else if (value1 !== value2) {
      dataBefore[fullPath] = value1;
      dataAfter[fullPath] = value2;
    }
  });

  return { dataBefore, dataAfter };
}
