import type { Ref } from "vue";

import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import type { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useImageControls } from "~~/composables/widgets/card/useImageControls";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

export const useImageWidgetConfig = (widget: Ref<IWidgetWithFields>) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  };

  const DROPDOWN_ITEMS = [generateDropdownItem("Image", "image")];

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Image"),
        showConfig: true,
        onConfigClick() {
          widgetSettingsStore.setActiveElement(
            widget.value,
            fields.value.image.field
          );
        },
        controls: [
          {
            componentPath: "ConfigMediaFileUpload",
            valuePath: "value",
            valueSource: fields.value.image.field,
            options: {
              label: "Image",
              active: true,
            },
          },
        ],
      },
    ];
  });

  const { imageControls } = useImageControls(fields);

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    imageControls,
  };
};
