import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  AlignItems,
  Alignment,
  Display,
  FlexDirection,
  JustifyContent,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  generateArrows,
  generatePagination,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/promo-bets-fenix-widget-css";
import { CardsDisplayMode } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetTypes";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCustomStyles,
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateIconSizeCssString,
  generateStageButtonCssString,
  generateIconCssString as generateIconColorCssString,
  generateFlexibleImageCssString,
  generateCardCssString,
} from "../utils/form-helper-functions";
import {
  generateBorderStyle,
  generateCornerRadiusStyle,
  generateFlex,
  generateGap,
  generateGrid,
  generatePaddingStyle,
  generateSize,
  generateWidth,
} from "../helpers";
import {
  getFlexAlign,
  getFlexPosition,
  getWidthStyles,
} from "../../widget-settings";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { getPxValueFromNumber, styleObjectToStringAdvanced } from "../..";

import {
  generateFavouriteIconCssString,
  generateHoverContentStyles,
  generateTagStyles,
} from "./casino-games-list-widget-css";
import { generateFieldContainerCssString } from "./search-field-widget-css";
import {
  generateCardFullAlignment,
  generateGridLayoutCssString,
  generatePromoButtonCssString,
  generatePromoButtonsGroupCssString,
} from "./promotions-list-widet-css";
import { generateCardImageAlignmentCssString } from "./payment-providers-list-fenix-widget-css";

export const generatePageTitleCssString =
  (field: IWidgetOptions, hoverField?: IWidgetOptions) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: field.options.icon?.gap || field.options.distanceBetweenItems || "",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content:
        flex + generateDefaultStyles(field.options) + generateWidth(100, "%"),
    });

    if (hoverField) {
      cssString += generateCssClassWithContent({
        className: field.options._cssClass,
        pseudoClassName: ":hover",
        childClassName: hoverField.options._cssClass,
        content: "opacity: 1;",
      });
    }

    if (field.options.icon) {
      cssString += generateCssClassWithContent({
        className: field.options.icon._cssClass,
        content: field.options.icon,
        customFunction: generateDefaultStyles,
      });
    }

    const states = field.options.states;

    if (states) {
      for (const state in states) {
        cssString += generateCssClassWithContent({
          className: field.options._cssClass,
          pseudoClassName: `:${state}`,
          content: states[state],
          customFunction: generateDefaultStyles,
        });
        if (!field.options.icon) continue;

        cssString += generateCssClassWithContent({
          className: field.options._cssClass,
          pseudoClassName: `:${state}`,
          childClassName: field.options.icon._cssClass,
          content: states[state].icon,
          customFunction: generateDefaultStyles,
        });
      }
    }

    return cssString;
  };

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateSize(options.size) + generateDefaultStyles(options),
    });

    // For casino smartgames widget
    if (options._wrapperCssClass) {
      cssString += generateCssClassWithContent({
        className: options._wrapperCssClass,
        content:
          "width:100%;display: flex; flex-direction: column;" +
          generateGap(options.display.distance),
      });
    }

    return cssString;
  };

export const generateHeaderStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;
    const mode = options.layout;

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      wrap: mode === undefined || mode === 3 ? "nowrap" : "wrap",
      gap: options.distance || "",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + generateDefaultStyles(options),
    });

    return cssString;
  };

const generateSortingStyles =
  (field: IWidgetOptions, header: IWidgetOptions) =>
  (cssString: string): string => {
    const mode = header.options.layout;
    const { options } = field;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "center",
    });

    const order = mode === 1 ? "1" : "initial";

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      content: `order: ${order};`,
    });

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      childClassName: "button-group",
      content: flex + "min-width: 175px;",
    });

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      childClassName: `button-group .${options._cssClass}:first-child`,
      content:
        generateBorderStyle(options.border) +
        "border-top-right-radius:0!important; border-bottom-right-radius: 0 !important",
    });

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      childClassName: `button-group .${options._cssClass}:last-child`,
      content:
        generateBorderStyle(options.border) +
        "border-top-left-radius:0!important; border-bottom-left-radius: 0 !important",
    });

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      childClassName: `button-group .${options._cssClass}[data-active="true"]`,
      content: generateDefaultStyles(options.states.active),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options) + "flex: 1 1 50%;",
    });

    return cssString;
  };

const generateSearchWrapperStyles =
  (field: IWidgetOptions, header: IWidgetOptions) =>
  (cssString: string): string => {
    const mode = header.options.layout;
    const { options } = field;

    const width = mode === 2 ? "auto" : getWidthStyles(options.width).width;
    const flexGrow = mode === 3 || mode === 2 ? "1" : "0";

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      content: `width: ${width}; flex-grow: ${flexGrow};`,
    });

    return cssString;
  };

const generateGamesListStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      direction: "column",
      gap: options.distance || "",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateGamesWrapperStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      direction: "column",
      gap: options.distance || "",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + generateWidth(100, "%"),
    });

    cssString += generateDefaultStylesWithStates(field, false);

    return cssString;
  };

const generateQueryStyles =
  (queryOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: queryOptions._cssClass,
      content: generateDefaultStyles(queryOptions),
    });

    return cssString;
  };

export const generateGameCardsListStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;
    const isSlider = options.mode === 2;

    const grid = generateGrid({
      rowGap: options.gap.row,
      columnGap: options.gap.col,
      templateColumns: !isSlider
        ? `repeat(${options.maxGamesRow}, minmax(0, 1fr))`
        : "",
      autoColumns: isSlider ? `${Math.round(100 / options.maxGamesRow)}%` : "",
      gridAutoFlow: isSlider ? "column" : "",
      autoRows: "1fr !important;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: grid + generateWidth(100, "%") + generateDefaultStyles(options),
    });

    if (isSlider) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: "overflow: auto;overflow-y:hidden;",
      });
    }

    return cssString;
  };

export const generateGameCardsStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      direction: "column",
      gap: options.distance,
      alignSelf: "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + generateDefaultStyles(options) + "height:100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      childClassName: "casino-game-card",
      content: "min-height:100px;width:100%;height:100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      childClassName: "casino-game-card__inner",
      content: "img {height:100%;}",
    });

    return cssString;
  };

export const generateNoGamesDescriptionStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    cssString += generateCssClassWithContent({
      className: options.description._cssClass,
      content: generateDefaultStyles(options.description, ["textColor"]),
    });

    return cssString;
  };

export const generateShowMoreGamesCard = (element: IWidgetOptions) => {
  return (cssString: string): string => {
    const { options } = element;
    const flex =
      generateFlex({
        flex: Display.FLEX,
        direction: FlexDirection.COLUMN,
        align: AlignItems.CENTER,
        justify: options.alignment,
      }) + "width:100%;height:100%;";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + generateDefaultStyles(options),
    });

    return cssString;
  };
};

export const generateGameCoverStyles =
  (element: IWidgetOptions, cardClass: string) =>
  (cssString: string): string => {
    const { options } = element;
    const states = options.states;

    const flexStyles = generateFlex({
      flex: "flex",
      align: getFlexPosition(options.display.alignment),
      justify: "space-between",
      direction:
        options.display.position === Alignment.RIGHT ? "row" : "row-reverse",
    });

    cssString += generateCssClassWithContent({
      className: cardClass,
      pseudoClassName: `:has(.${options._cssClass})`,
      content: generateDefaultStyles(options),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: ":not(img)",
      content:
        flexStyles +
        generateDefaultStyles(options, ["padding", "fill"]) +
        generatePaddingStyle(options.image.padding),
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass}[alt]`,
      content: generateCornerRadiusStyle(options.image.cornerRadius),
    });

    for (const state in states) {
      cssString += generateCssClassWithContent({
        className: cardClass,
        pseudoClassName: `:has(.${options._cssClass}):${state}`,
        content: {
          ...states[state],
          fill: null,
        },
        customFunction: generateDefaultStyles,
      });
    }

    return cssString;
  };

export const generatePromoGroupListCssString = (
  element: IWidgetOptions,
  cellElement: IWidgetOptions
) => {
  return (cssString: string): string => {
    if (element.options.displayMode === CardsDisplayMode.Grid) {
      cssString += generateGridLayoutCssString(element);
    } else if (element.options.displayMode === CardsDisplayMode.Carousel) {
      const alignItems = getFlexAlign(element.options.carousel.alignment)[
        "align-items"
      ];

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        content: generateFlex({
          flex: Display.FLEX,
          align: alignItems,
          justify: JustifyContent.NORMAL,
          gap: element.options.carouselSettings.gap,
        }),
      });

      let stylesObject = {};
      if (element.options.carouselSettings.layout === ResizingType.FIXED) {
        stylesObject = {
          width: getPxValueFromNumber(element.options.carouselSettings.width),
        };
      } else {
        stylesObject = {
          width: `calc((100% - (${
            element.options.carouselSettings.maxVisibleCards
          } - 1) * ${getPxValueFromNumber(
            element.options.carouselSettings.gap
          )}) / ${element.options.carouselSettings.maxVisibleCards})`,
        };
      }

      cssString += generateCssClassWithContent({
        className: cellElement.options._cssClass,
        content: styleObjectToStringAdvanced({
          flexShrink: 0,
          ...stylesObject,
        }),
      });
    }
    return cssString;
  };
};
export const generateCasinoGamesListFenixWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.page_title.options.icon._cssClass =
    generateClassName("page_title_icon");

  prefilled.sorting.options._wrapperCssClass =
    generateClassName("sorting_wrapper");

  prefilled.search.options._wrapperCssClass =
    generateClassName("search_wrapper");
  prefilled.search.options.text._cssClass = generateClassName("search_text");
  prefilled.search.options.icon._cssClass = generateClassName("search_icon");

  prefilled.games_category_title.options.icon._cssClass = generateClassName(
    "games_category_title_icon"
  );

  prefilled.search_result.options.query._cssClass = generateClassName(
    "search_result_query"
  );

  prefilled.games_category_nogames_title.options.description._cssClass =
    generateClassName("games_category_nogames_description");

  prefilled.all_games_nogames_title.options.description._cssClass =
    generateClassName("all_games_nogames_description");

  // const cardLayout = prefilled.promo_cards.options.layoutPosition.position;
  // let cardsStretchStyle = `.${widget.options._cssClass} .${prefilled.promo_cards.options._cssClass} .card-element:last-child{align-self:stretch;`;

  // if (cardLayout === TextPosition.BOTTOM || cardLayout === TextPosition.TOP) {
  //   cardsStretchStyle += `flex:1;`;
  // }

  // cardsStretchStyle += "}";

  const cardsGapStyles = `.${widget.options._cssClass} .${
    prefilled.promo_cards.options._cssClass
  }{${generateGap(prefilled.promo_cards.options.gap)}}`;

  const cardsTextGapStyles = `.${widget.options._cssClass} .${
    prefilled.promo_cards.options._cssClass
  } .card-element__text{${generateGap(prefilled.promo_cards.options.gap)}}`;

  let cardHeightValue = "height:auto;";

  if (
    prefilled.promo_cards.options.containerSize.height.type ===
    ResizingType.FIXED
  ) {
    cardHeightValue = `height:${getPxValueFromNumber(
      prefilled.promo_cards.options.containerSize.height.value
    )};`;
  }

  const cardHeightStyles = `.${widget.options._cssClass} .${prefilled.promo_cards.options._cssClass}{${cardHeightValue}}`;

  prefilled.promo_button_1.options.iconSettings._cssClass =
    generateClassName("promo_button-icon");

  prefilled.promo_button_2.options.iconSettings._cssClass = generateClassName(
    "promo_button_2-icon"
  );

  prefilled.play_free_button.options.iconSettings._cssClass = generateClassName(
    "play_free_button_icon"
  );

  prefilled.play_money_button.options.iconSettings._cssClass =
    generateClassName("play_money_button_icon");

  prefilled.back_button.options.iconSettings._cssClass =
    generateClassName("back_button_icon");

  prefilled.show_more_button.options.iconSettings._cssClass = generateClassName(
    "show_more_button_icon"
  );

  const arrows = widget.content["arrows"];
  if (arrows) {
    arrows.options._cssClass = generateClassName("arrows");
    arrows.options._containerCssClass = generateClassName("arrowsContainer");
    arrows.options.button._cssClass = generateClassName("arrowsButton");
  }

  const pagination = widget.content["pagination"];
  if (pagination) {
    pagination.options._cssClass = generateClassName("pagination");
    pagination.options._dotsCssClass = generateClassName("paginationDots");
  }

  prefilled.games_category_button.options.iconSettings._cssClass =
    generateClassName("games_category_button_icon");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),

    generateGamesListStyles(prefilled.games_list),

    generatePageTitleCssString(prefilled.page_title),
    generateIconColorCssString(prefilled.page_title.options.icon, "textColor"),
    generateIconSizeCssString(prefilled.page_title.options.icon, "iconSize"),
    generateStringDefault(prefilled.search_result),
    generateStringDefault(prefilled.game_name),
    generateCustomStyles(prefilled.search_result.options._cssClass, {
      width: "100%",
    }),
    generateQueryStyles(prefilled.search_result.options.query),

    generateHeaderStyles(prefilled.header),
    generateSortingStyles(prefilled.sorting, prefilled.header),
    generateFieldContainerCssString(prefilled.search as IWidgetField),
    generateSearchWrapperStyles(prefilled.search, prefilled.header),

    generateGamesWrapperStyles(prefilled.games_category),
    generateGamesWrapperStyles(prefilled.all_games),

    generatePageTitleCssString(prefilled.all_games_title),
    generatePageTitleCssString(
      prefilled.games_category_title,
      prefilled.games_category_hover_button
    ),

    generateStageButtonCssString(
      prefilled.games_category_button as IWidgetField
    ),
    generateStageButtonCssString(
      prefilled.games_category_hover_button as IWidgetField
    ),
    generateCustomStyles(
      prefilled.games_category_hover_button.options._cssClass,
      { opacity: "0" }
    ),

    generateStringDefault(prefilled.all_games_nogames_title),
    generateStringDefault(prefilled.games_category_nogames_title),

    generateNoGamesDescriptionStyles(prefilled.all_games_nogames_title),
    generateNoGamesDescriptionStyles(prefilled.games_category_nogames_title),

    generateGameCardsListStyles(prefilled.games_category_list),
    generateGameCardsListStyles(prefilled.all_games_list),

    generateGameCardsStyles(prefilled.game_cards),

    prefilled.show_more_games_card
      ? generateShowMoreGamesCard(prefilled.show_more_games_card)
      : cssString => cssString,

    generateGameCoverStyles(prefilled.game_cover, "casino-game-card"),
    generateHoverContentStyles(
      prefilled.hover_content,
      prefilled.game_cover,
      "btn-group"
    ),

    generateStringWithStates(prefilled.game_title, true),
    generateStageButtonCssString(
      prefilled.play_money_button as IWidgetField,
      false
    ),
    generateStageButtonCssString(prefilled.back_button as IWidgetField),
    generateStageButtonCssString(prefilled.show_more_button as IWidgetField),
    generateStageButtonCssString(
      prefilled.play_free_button as IWidgetField,
      false
    ),

    generateFavouriteIconCssString(prefilled.favorite_icon),
    generateTagStyles(prefilled.promo_tag),

    generateStringDefault(prefilled.promo_group_list),
    generatePromoGroupListCssString(
      prefilled.promo_group_list,
      prefilled.promo_cards
    ),
    generateFlexibleImageCssString(prefilled.promo_image as IWidgetField),
    generateStringDefault(prefilled.promo_title),
    generateStringDefault(prefilled.promo_description),
    generatePromoButtonCssString(prefilled.promo_button_1 as IWidgetField),
    generatePromoButtonCssString(prefilled.promo_button_2 as IWidgetField),
    generatePromoButtonsGroupCssString(
      prefilled.promo_button_group as IWidgetField
    ),

    generateCardCssString(
      prefilled.promo_cards,
      cardsGapStyles + cardsTextGapStyles + cardHeightStyles
    ),
    generateCardFullAlignment(prefilled.promo_cards as IWidgetField),
    generateCardImageAlignmentCssString(prefilled.promo_cards),

    arrows ? generateArrows(arrows) : cssString => cssString,
    pagination ? generatePagination(pagination) : cssString => cssString
  )("");
};
