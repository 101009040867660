import { type Ref } from "vue";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";

import { IFields } from "../useWidgetFields";

export function useImageControls(
  fields: Ref<IFields> | null,
  customImageField?: Ref<IWidgetField | null>,
  attribute?: Ref<string>
) {
  const widgetSettingsStore = useWidgetSettingsStore();

  const currentField = computed<IWidgetField>(() => {
    if (!customImageField?.value) {
      return fields!.value.image.field;
    }

    return customImageField.value;
  });

  const imageControls = computed(() => [
    {
      componentPath: "ConfigMediaFileUpload",
      valuePath: "value",
      valueSource: currentField.value,
      options: {
        active: currentField.value.options._active,
        attribute: attribute?.value ?? "image",
      },
    },
    {
      componentPath: "ConfigContainerSize",
      valuePath: "options.containerSize",
      valueSource: currentField.value,
      options: {},
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: currentField.value,
      options: {
        label: "Border",
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: currentField.value,
      options: {
        label: "Shadow",
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: currentField.value,
      options: {
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigLinkType",
      valuePath: "options.link",
      valueSource: currentField.value,
      options: {
        label: "Link",
        shouldShowStyleSettings: false,
        "onShow-states-settings": () => {
          widgetSettingsStore.setDropdownItem({ name: "linkStates" });
        },
      },
    },
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: currentField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
    {
      section: generateContentSectionName("SEO settings"),
      controls: [
        {
          componentPath: "content/ConfigTextInput",
          valuePath: "options._seo.title",
          valueSource: currentField.value,
          options: {
            label: "Title for search engines",
            placeholder: "Add title text here",
          },
        },
        {
          componentPath: "content/ConfigTextInput",
          valuePath: "options._seo.alt",
          valueSource: currentField.value,
          options: {
            label: "ALT text",
            placeholder: "Add alt text here",
          },
        },
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options._useLazyLoad",
          valueSource: currentField.value,
          options: {
            label: "Use lazy load",
            items: [
              { label: "Yes", value: true },
              { label: "No", value: false },
            ],
          },
        },
      ],
    },
    {
      componentPath: "common/ConfigRadioTextElements",
      valuePath: "options._isImagePreload",
      valueSource: currentField.value,
      options: {
        label: "Should image preload",
        items: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  return {
    imageControls,
  };
}
