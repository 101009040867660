<script lang="ts" setup>
import { VITE_DASHBOARD_BASE_URL } from "~~/constants";
import { ReplaceMode } from "~~/components/config/helpers";
import { useContentGlobal } from "~~/components/config/composables/useContentGlobal";

const emit = defineEmits<{
  (e: "close"): void;
}>();

const validateValue = (value?: string): string | boolean => {
  const edited = (value || "").trim();

  if (!edited) {
    return true;
  }

  if (!edited.startsWith("/")) {
    return 'Value should start with "/"';
  }

  if (edited.includes(":")) {
    return "Value should not include dynamic params";
  }

  return true;
};

const {
  handleApplyToPageClick,
  handleApplyAllClick,
  handleFetchAllClick,
  handleInputUpdate,

  similarPages,
  listLoading,
  pagesWithErrors,
  loading,
  mode,
  tableData,
  rowSelection,
  cols,
  similarTableColumns,
  ignoredPagesWithSimilarWidgets,
  langsSearchData,
  submitDisabled,
} = useContentGlobal({
  emit,
  replaceMode: ReplaceMode.URL,
  validate: validateValue,
});
</script>

<template>
  <a-typography-title :level="5">Change url:</a-typography-title>
  <a-radio-group
    v-model:value="mode"
    :style="{ marginBottom: '8px' }"
  >
    <a-radio-button value="single">Change on current page</a-radio-button>
    <a-radio-button value="all">Change on all pages</a-radio-button>
  </a-radio-group>

  <a-table
    :data-source="tableData"
    :columns="cols"
    :pagination="false"
    row-key="key"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'search'">
        <a-form-item
          :validate-status="
            langsSearchData[record.langKey].searchValid === true ? '' : 'error'
          "
          :help="langsSearchData[record.langKey].searchValid"
        >
          <a-input
            :value="langsSearchData[record.langKey].search"
            :status="
              langsSearchData[record.langKey].searchValid === true
                ? ''
                : 'error'
            "
            class="content-tools__input"
            placeholder="Search"
            @update:value="handleInputUpdate($event, 'search', record.langKey)"
          />
        </a-form-item>
      </template>

      <template v-if="column.dataIndex === 'change'">
        <a-form-item
          :validate-status="
            langsSearchData[record.langKey].changeValid === true ? '' : 'error'
          "
          :help="langsSearchData[record.langKey].changeValid"
        >
          <a-input
            :value="langsSearchData[record.langKey].change"
            :status="
              langsSearchData[record.langKey].changeValid === true
                ? ''
                : 'error'
            "
            placeholder="Change to"
            class="content-tools__input"
            @update:value="handleInputUpdate($event, 'change', record.langKey)"
          />
        </a-form-item>
      </template>
    </template>
  </a-table>

  <div
    v-if="mode === 'all' && similarPages.length"
    class="m-t-8"
  >
    <a-typography-title :level="5"
      >Similar pages list({{ similarPages.length }}):</a-typography-title
    >

    <a-table
      :data-source="similarPages"
      :columns="similarTableColumns"
      :row-selection="rowSelection"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <a
            :href="`${VITE_DASHBOARD_BASE_URL()}/pages/${record.id}/setup`"
            target="_blank"
          >
            {{ record.name }}
          </a>
        </template>
      </template>
    </a-table>
  </div>

  <div
    v-if="ignoredPagesWithSimilarWidgets.length"
    class="m-t-16"
  >
    <p class="m-b-8">These pages were ignored. Please update them manually</p>
    <div>
      <p
        v-for="pageId in ignoredPagesWithSimilarWidgets"
        :key="pageId"
        class="m-b-0"
      >
        <a
          :href="`${VITE_DASHBOARD_BASE_URL()}/pages/${pageId}/setup`"
          target="_blank"
        >
          {{ pagesWithErrors[pageId].route }}
        </a>
      </p>
    </div>
  </div>

  <div class="m-t-16 content-tools__apply">
    <a-button
      v-if="mode === 'single'"
      :disabled="submitDisabled"
      type="primary"
      @click="handleApplyToPageClick"
    >
      Apply changes
    </a-button>
    <template v-else>
      <a-button
        type="primary"
        class="m-r-4"
        :disabled="submitDisabled"
        :loading="listLoading"
        @click="handleFetchAllClick"
      >
        Fetch pages
      </a-button>

      <a-button
        type="secondary"
        :disabled="!similarPages.length || submitDisabled"
        :loading="loading"
        @click="handleApplyAllClick"
      >
        Apply changes to selected pages
      </a-button>
    </template>
  </div>
</template>

<style lang="scss">
.content-tools {
  &__apply {
    display: flex;
    justify-content: flex-end;
  }

  &__input {
    &[status="error"] {
      border-color: $c-icon-font-red;
    }
  }

  &__error {
    font-size: 14px;
    color: $c-icon-font-red;
  }
}
</style>
