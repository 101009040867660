<template>
  <div
    :style="containerStyles"
    class="transaction-filter-fenix"
  >
    <WidgetField
      :widget="widget"
      :field="fields.date_filter.field"
      :style="containerWidthStyles.dates"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.date_filter.field"
      >
        <WidgetField
          v-if="fields.date_filter_title.active"
          :widget="widget"
          :field="fields.date_filter_title.field"
        >
          <BasicTitleField :field="fields.date_filter_title.field" />
        </WidgetField>

        <div :style="dateFilterStyles">
          <WidgetField
            :widget="widget"
            :field="objectFields.date_from"
            class="transaction-filter-fenix__item"
            no-overflow
          >
            <FormDateField
              :ref="
                  (el: TriggerComponent) => {
                    if (el) fieldsRefsList[0] = el;
                  }
                "
              :field="objectFields.date_from"
              :styling-fields="dateInputStyleFields"
              :states="states"
            />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="objectFields.date_to"
            class="transaction-filter-fenix__item"
            no-overflow
          >
            <FormDateField
              :ref="
                  (el: TriggerComponent) => {
                    if (el) fieldsRefsList[1] = el;
                  }
                "
              :field="objectFields.date_to"
              :styling-fields="dateInputStyleFields"
              :states="states"
            />
          </WidgetField>
        </div>
      </BasicDesignWrapperField>
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="fields.transactions_filter.field"
      :style="containerWidthStyles.transactions"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.transactions_filter.field"
      >
        <WidgetField
          v-if="fields.transactions_filter_title.active"
          :widget="widget"
          :field="fields.transactions_filter_title.field"
        >
          <BasicTitleField :field="fields.transactions_filter_title.field" />
        </WidgetField>

        <div :style="transactionsFilterStyles">
          <template
            v-if="
              fields.transactions_filter.field.options.itemsType === 'dropdown'
            "
          >
            <WidgetField
              v-if="objectFields.transaction_types?.options?._active"
              :widget="widget"
              :field="objectFields.transaction_types"
              class="transaction-filter-fenix__item"
            >
              <FormDropdownField
                :ref="
                    (el: TriggerComponent) => {
                      if (el) fieldsRefsList[2] = el;
                    }
                  "
                :field="objectFields.transaction_types"
                :styling-fields="marketInputStyleFields"
                :states="states"
              />
            </WidgetField>

            <WidgetField
              v-if="objectFields.transaction_subtypes?.options?._active"
              :widget="widget"
              :field="objectFields.transaction_subtypes"
              class="transaction-filter-fenix__item"
            >
              <FormDropdownField
                :ref="
                    (el: TriggerComponent) => {
                      if (el) fieldsRefsList[3] = el;
                    }
                  "
                :field="objectFields.transaction_subtypes"
                :styling-fields="marketInputStyleFields"
                :states="states"
              />
            </WidgetField>
          </template>
          <template v-else>
            <WidgetField
              v-if="objectFields.transaction_types?.options?._active"
              :widget="widget"
              :field="objectFields.transaction_types"
              class="transaction-filter-fenix__item transaction-filter-fenix__radio-buttons"
            >
              <FormRadioGroupField
                :ref="
                    (el: TriggerComponent) => {
                      if (el) fieldsRefsList[2] = el;
                    }
                  "
                :field="objectFields.transaction_types"
                :styling-fields="radioStyleFields"
                :states="states"
                :items="[
                  { label: 'Type' },
                  { label: 'Type' },
                  { label: 'Type' },
                ]"
              />
            </WidgetField>

            <WidgetField
              v-if="objectFields.transaction_subtypes?.options?._active"
              :widget="widget"
              :field="objectFields.transaction_subtypes"
              class="transaction-filter-fenix__item transaction-filter-fenix__checkbox-buttons"
            >
              <FormInputBase
                :ref="
                    (el: TriggerComponent) => {
                      if (el) fieldsRefsList[3] = el;
                    }
                  "
                :widget="widget"
                :field="objectFields.transaction_subtypes"
                :styling-fields="stylingFields"
                :is-tooltip-visible="false"
              >
                <FormCheckboxField
                  :field="objectFields.transaction_subtypes"
                  :styling-fields="checkboxStyleFields"
                  :states="states"
                  :items="[
                    { label: 'Subtype' },
                    { label: 'Subtype' },
                    { label: 'Subtype' },
                  ]"
                />
              </FormInputBase>
            </WidgetField>
          </template>
        </div>
      </BasicDesignWrapperField>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { TriggerComponent } from "~~/models/widgets/form.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getWidthStyles } from "~~/assets/utils/widget-settings";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useFormInner } from "~~/composables/widgets/form/useFormInner";
import { FormElementName } from "~~/models/widgets/form.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { CellSizeType } from "~~/models/grid.interface";
import { FormStyleFieldName } from "~~/models/widgets/form.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  fields: IWidgetField[];
  states: Record<string, State>;
}>();

const fields = useWidgetFields(props.widget);

const objectFields = computed<Record<string, IWidgetField>>(() => {
  return props.fields.reduce((result, curr) => {
    return {
      ...result,
      [curr.name]: curr,
    };
  }, {});
});

const filterFormFields = computed<IWidgetField[]>(() => {
  return [
    objectFields.value.date_from,
    objectFields.value.date_to,
    objectFields.value.transaction_types,
    objectFields.value.transaction_subtypes,
  ];
});

const { fieldsRefsList, getFieldStylings } = useFormInner(
  props.widget,
  filterFormFields
);

const dateFilterStyles = computed<ElementStyle>(() => {
  const settings = fields.value.date_filter.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    gap: getPxValueFromNumber(settings.distance),
    alignItems: "flex-end",
  };
});

const transactionsFilterStyles = computed<ElementStyle>(() => {
  const settings = fields.value.transactions_filter.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: props.field.options.displayMode === 3 ? "column" : "row",
    gap: getPxValueFromNumber(settings.distance),
  };
});

const containerWidthStyles = computed<Record<string, ElementStyle>>(() => {
  const settingsDates = fields.value.date_filter.field.options;
  const settingsTransactions = fields.value.transactions_filter.field.options;

  return {
    dates: {
      width: getWidthStyles(settingsDates.width).width,
      flex:
        settingsDates.width.sizeType === CellSizeType.FILL ? "1" : "initial",
    },
    transactions: {
      width: getWidthStyles(settingsTransactions.width).width,
      minWidth: 0,
      flex:
        settingsTransactions.width.sizeType === CellSizeType.FILL
          ? "1"
          : "initial",
    },
  };
});

const containerStyles = computed<ElementStyle>(() => {
  const settings = props.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    ...getCommonStyles(props.field.options),
    flexDirection: settings.displayMode === 2 ? "row" : "column",
    gap: getPxValueFromNumber(props.field.options.distance),
  };
});

const dateInputStyleFields = computed<IWidgetField[]>(() => {
  return getFieldStylings(FormElementName.DATE);
});

const marketInputStyleFields = computed<IWidgetField[]>(() => {
  return getFieldStylings(FormElementName.DROPDOWN);
});

const checkboxStyleFields = computed<IWidgetField[]>(() => {
  return [
    fields.value[FormStyleFieldName.CHECKBOX].field,
    // fields.value[FormStyleFieldName.FIELD].field,
    // fields.value[FormStyleFieldName.LABEL].field,
    // fields.value[FormStyleFieldName.ERROR].field,
    // fields.value[FormStyleFieldName.TOOLTIP].field,
  ];
});

const stylingFields = computed<IWidgetField[]>(() => {
  return [
    fields.value[FormStyleFieldName.FIELD].field,
    fields.value[FormStyleFieldName.LABEL].field,
    fields.value[FormStyleFieldName.ERROR].field,
    fields.value[FormStyleFieldName.TOOLTIP].field,
  ];
});

const radioStyleFields = computed<IWidgetField[]>(() => {
  return [
    fields.value[FormStyleFieldName.RADIO_GROUP].field,
    fields.value[FormStyleFieldName.FIELD].field,
    fields.value[FormStyleFieldName.LABEL].field,
    fields.value[FormStyleFieldName.ERROR].field,
    fields.value[FormStyleFieldName.TOOLTIP].field,
  ];
});
</script>

<style lang="scss">
.transaction-filter-fenix {
  &__item {
    flex: 1;
  }

  &__radio-buttons {
    @include flex(center, flex-start, 0);
    min-width: 0;
    // overflow: hidden;
  }

  &__checkbox-buttons {
    @include flex(center, flex-start, 0);
    min-width: 0;
    // overflow: hidden;
  }
}
</style>
