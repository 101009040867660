export const MAX_TEXT = 255;
export const MAX_TEXTAREA = 500;
export const MAX_EDITOR = 10000;
export const MAX_BUTTON = 50;
export const MAX_IMAGE_KB = 1024;
export const MAX_VIDEO_KB = 1024 * 4;
export const MAX_AUDIO_KB = 1024;
export const MAX_DEFAULT_SIZE_FILES_KB = 1024 * 10;
export const IMAGE_VALID_FORMATS = ["png", "jpg", "jpeg"];
export const IMAGE_VALID_ACCEPTED_FORMATS = IMAGE_VALID_FORMATS.map(
  f => `image/${f}`
);
export const VIDEO_VALID_FORMATS = ["mp4", "webm"];
export const VIDEO_VALID_ACCEPTED_FORMATS = VIDEO_VALID_FORMATS.map(
  f => `video/${f}`
);
export const AUDIO_VALID_FORMATS = ["mp3"];
export const DEFAULT_VALID_FORMATS = [
  "mp3",
  "png",
  "jpg",
  "jpeg",
  "gif",
  "mp4",
  "doc",
  "docx",
  "rtf",
  "pdf",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
];

export const MIN_NUMBER_INPUT_VALUE = -10000;

export const MAX_NUMBER_INPUT_VALUE = 10000;

export const MIN_ICON_SIZE_INPUT_VALUE = 12;

export const MAX_ICON_SIZE_INPUT_VALUE = 48;

export const MIN_GAP_INPUT_VALUE = 4;

export const MAX_GAP_INPUT_VALUE = 32;
