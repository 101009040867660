import { useWidgetsStore } from "~~/store/widgets";
import { prefillEmptyOptions } from "~~/assets/utils/widget";
import { Sizing } from "~~/models/grid.interface";
import {
  VerticalAlignment,
  State,
  TextStyle,
  DisplayOrientation,
  Alignment,
  ResizingType,
  SportMenuLiveBet9CardLayoutMode,
  Grid,
} from "~~/models/widgets/widget-controls.model";
import {
  ICustomField,
  IWidgetField,
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillImage,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSizeValue,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { TEXT_BUTTON_INITIAL } from "~~/constants/configs/common/text-button-initial";
import {
  BUTTON_INITIAL,
  TITLE_INITIAL,
} from "~~/constants/configs/card-common/initial-constants";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import { generateWidgetField, isSimpleValue } from "~~/assets/utils/widget";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getDefaultSpacing,
  getDefaultFillValue,
} from "~~/constants/configs/common/widget-initial";
import {
  generateFormFields,
  prefillCustomFields,
} from "~~/assets/utils/widget/form";
import {
  FormFieldDetails,
  FormStyleFieldName,
} from "~~/models/widgets/form.model";
import { InnerFieldValue } from "~~/models/page.model";
import { useWidgetCacheData } from "~~/composables/widgets/common/useWidgetCacheData";
import { useWidthConfig } from "~~/composables/widgets/common/useWidthConfig";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import { FieldType } from "~~/models/widgets/widget.core/field-types.enum";
import {
  ITEMS_GROUP_INITIAL,
  TITLE_INITIAL as DROPDOWN_TITLE_INITIAL,
} from "~~/constants/configs/dropdown/initial-data";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";

const LIST_FILTER_FIELDS = [
  "filter",
  "date_from",
  "date_to",
  "markets",
  "fields_styling",
  "labels_styling",
  "errors_styling",
  "tooltips_styling",
  "calendar_styling",
  "datepicker_styling",
  "dropdowns_styling",
  "dropdown_menu_styling",
  "dropdown_menu_items_styling",
];

export const useSportsbookInitData = (widget: IWidgetWithFields) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget);
  const { widgetCacheData } = useWidgetCacheData(widget);
  const { parentCell } = useWidthConfig();
  const { initWidgetOptions } = useCreateFields(widget);

  const arrayField = computed<IWidgetField | null>(() => {
    return widget.fields.find(field => field.name === FieldName.ITEMS) || null;
  });

  const itemsGroup = computed<ICustomField | null>(() => {
    if (!arrayField.value?.value) {
      return null;
    }

    if (!Array.isArray(arrayField.value.value)) {
      return null;
    }

    return arrayField.value.value[0] || null;
  });

  const menuItemsOptions = computed<IWidgetOptions>(() => ({
    ...itemsGroup.value?.options,
  }));

  const itemsFields = computed<IWidgetField[]>(() => {
    return itemsGroup.value?.fields || [];
  });

  const itemsGroupFieldExists = (
    parentField: ICustomField[] | null
  ): boolean => {
    if (!parentField) {
      return false;
    }

    if (!Array.isArray(parentField)) {
      return false;
    }

    const field = parentField.find(item => item.name === FieldName.ITEMS_GROUP);

    return !!field;
  };

  const initItemsGroup = () => {
    const itemsGroupExists = itemsGroupFieldExists(
      arrayField.value?.value as ICustomField[] | null
    );

    if (itemsGroupExists) {
      return;
    }

    arrayField.value!.value = [
      {
        ...generateWidgetField(FieldName.ITEMS_GROUP, widget.id),
        fields: [
          generateWidgetField(
            FieldName.EVENTS,
            widget.id,
            FieldType.TEXT_INPUT,
            "Events",
            "Events"
          ),
          generateWidgetField(
            FieldName.TOP_LEAGUES,
            widget.id,
            FieldType.TEXT_INPUT,
            "Top Leagues",
            "Top Leagues"
          ),
          generateWidgetField(
            FieldName.ALL_LEAGUES,
            widget.id,
            FieldType.TEXT_INPUT,
            "All Leagues",
            "All Leagues"
          ),
          generateWidgetField(
            FieldName.LEAGUE_WINNER,
            widget.id,
            FieldType.TEXT_INPUT,
            "League Winner",
            "League Winner"
          ),
        ],
        options: {},
      },
    ] as ICustomField[];
  };

  const switchField = computed<IWidgetField | null>(() => {
    return widget.fields.find(field => field.name === "switch") || null;
  });

  const switchItemsGroup = computed<ICustomField | null>(() => {
    if (!switchField.value?.value) {
      return null;
    }

    if (!Array.isArray(switchField.value.value)) {
      return null;
    }

    return switchField.value.value[0] || null;
  });

  const switchMenuItemsOptions = computed<IWidgetOptions>(() => ({
    ...switchItemsGroup.value?.options,
  }));

  const switchItemsFields = computed<IWidgetField[]>(() => {
    return switchItemsGroup.value?.fields || [];
  });

  const switchItemsGroupFieldExists = (
    parentField: ICustomField[] | null
  ): boolean => {
    if (!parentField) {
      return false;
    }

    if (!Array.isArray(parentField)) {
      return false;
    }

    const field = parentField.find(
      item => item.name === FieldName.SWITCH_ITEMS_GROUP
    );

    return !!field;
  };

  const switchInitItemsGroup = () => {
    const itemsGroupExists = switchItemsGroupFieldExists(
      switchField.value?.value as ICustomField[] | null
    );

    if (itemsGroupExists) {
      return;
    }

    if (switchField.value) {
      switchField.value.options._active = false;

      switchField.value.value = [
        {
          ...generateWidgetField(FieldName.SWITCH_ITEMS_GROUP, widget.id),
          fields: [
            generateWidgetField(
              FieldName.BY_DATE,
              widget.id,
              FieldType.TEXT_INPUT,
              "By Date",
              "By Date"
            ),
            generateWidgetField(
              FieldName.BY_LEAGUE,
              widget.id,
              FieldType.TEXT_INPUT,
              "By League",
              "By League"
            ),
          ],
          options: {},
        },
      ] as ICustomField[];
    }
  };

  const DEFAULT_STATES: Record<string, State> = {
    widget: State.SPORTSBOOK_SPORT,
    live_button_title: State.DEFAULT,
    winner_league_bet_items: State.DEFAULT,
    list_bet_item: State.DEFAULT,
    top_leagues_list_bet_item: State.DEFAULT,
    list_item_more: State.DEFAULT,
    top_leagues_list_item_more: State.DEFAULT,
    events: State.ACTIVE_EVENTS,
    event_cards: State.DEFAULT,
    top_leagues_events: State.ACTIVE_EVENTS,
    top_leagues_event_cards: State.DEFAULT,
    betbuilder_button: State.DEFAULT,
    top_leagues_betbuilder_button: State.DEFAULT,
    league_card: State.DEFAULT,
    events_container: State.WITH_ACTIVE_EVENTS,
    top_leagues_events_container: State.WITH_ACTIVE_EVENTS,
    items: State.SPORTSBOOK_TABS_EVENTS,
    itemsGroup: State.DEFAULT,
    switch: State.SPORTSBOOK_SWITCH_BY_LEAGUE,
    switchItemsGroup: State.DEFAULT,
    search_field: State.DEFAULT,
    search_result_items: State.DEFAULT,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
    [FormStyleFieldName.CALENDAR]: State.DEFAULT,
    [FormStyleFieldName.DROPDOWN_MENU_ITEMS]: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({ ...DEFAULT_STATES });

  /*
    Initialize widget with initial options
  */

  const initialWidgetOptions = initWidgetOptions(["fill"], {
    bindingParams: {},
    fillImageColor: getDefaultFillImage(),
    contentWidth: {
      type: Sizing.ADAPTIVE,
      width: 320,
      alignment: "center",
    },
    states: {
      [State.SPORTSBOOK_COUNTRY]: {
        _active: false,
      },
      [State.SPORTSBOOK_LEAGUE]: {
        _active: false,
      },
      [State.SPORTSBOOK_TOP_LEAGUES]: {
        _active: false,
      },
      [State.SPORTSBOOK_ALL_LEAGUES]: {
        _active: false,
      },
    },
  });

  /*
    This needed for dnd
  */
  if (
    parentCell.value?.settings.sizing === Sizing.FIXED &&
    initialWidgetOptions.contentWidth.type === Sizing.ADAPTIVE
  ) {
    initialWidgetOptions.contentWidth = {
      type: Sizing.FIXED,
      width: parentCell.value?.settings.width,
      alignment: initialWidgetOptions.contentWidth.alignment,
    };
  }

  const createFields = (): void => {
    if (!widgetCacheData.value) {
      return;
    }

    const widgetFields: IWidgetField[] = [];
    const formFields: IWidgetField[] = [];

    for (const key in widgetCacheData.value.fields) {
      const fieldDetails = widgetCacheData.value.fields[key];

      if (
        isSimpleValue(fieldDetails.value) ||
        useLocalizedValue().isLocalized(fieldDetails.value)
      ) {
        widgetFields.push(
          generateWidgetField(
            key,
            widget.id,
            fieldDetails.type,
            fieldDetails.title || "",
            fieldDetails.value,
            {},
            fieldDetails.validation
          )
        );

        continue;
      }

      const formFieldDetails = {
        value: fieldDetails.value as InnerFieldValue,
      } as FormFieldDetails;

      formFields.push(...generateFormFields(formFieldDetails, widget, key));

      formFields.push(
        generateWidgetField(
          key,
          widget.id,
          fieldDetails.type,
          fieldDetails.title,
          fieldDetails.value,
          {},
          fieldDetails.validation,
          undefined,
          undefined,
          true
        )
      );
    }

    widgetsStore.updateWidgetFields(widget, [...widgetFields, ...formFields]);
  };

  const initialTabItemsState = () => {
    return {
      fill: getDefaultFillValue(),
      border: getDefaultBorderValue(),
      shadow: {
        fill: getDefaultFillValue(),
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      color: getDefaultFillValue(),
      decoration: [],
    };
  };

  const ITEM_INITIAL = () => ({
    options: {
      _active: true,
      text: {
        _active: true,
      },
      icon: {
        _active: false,
        value: "ant-design:arrow-right-outlined",
      },
      fill: {
        color: "#000000",
        opacity: 100,
      },
    },
  });

  const ITEM_GROUP_INITIAL = () => ({
    options: {
      fill: getDefaultFillValue("#ffffff", 100),
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      spacing: {
        padding: getDefaultSpacing({
          top: 8,
          right: 8,
          bottom: 8,
          left: 8,
        }),
        margin: getDefaultSpacing({
          bottom: 16,
        }),
      },
      resizing: ResizingType.FILL,
      containerAlignment: Alignment.LEFT,
      distance: 16,
      color: getDefaultFillValue("#595959"),
      theme: 13,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
      alignment: Alignment.CENTER,
      itemsIcon: {
        textColor: getDefaultFillValue("#000000"),
        iconSize: 20,
        position: Alignment.LEFT,
        gap: 8,
      },
      states: {
        [State.HOVER]: initialTabItemsState(),
        [State.ACTIVE]: initialTabItemsState(),
        [State.DISABLED]: initialTabItemsState(),
      },
    },
  });

  const initWidgetFieldsOptions = (): void => {
    const initialItemsGroupOptions = prefillEmptyOptions(
      itemsGroup.value!.options,
      ITEM_GROUP_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      itemsGroup.value!,
      initialItemsGroupOptions
    );

    itemsFields.value.forEach(itemField => {
      widgetsStore.updateFieldOptions(
        itemField,
        prefillEmptyOptions(itemField.options, ITEM_INITIAL().options)
      );
    });

    const initialSwitchOptions = prefillEmptyOptions(
      fields.value.switch.field.options,
      getInitialOptions({
        data: {
          options: {
            ...DESIGN_INITIAL().options,
            _active: false,
          },
        },
        values: {
          _active: false,
        },
      }).options
    );

    const initialSwitchItemsGroupOptions = prefillEmptyOptions(
      switchItemsGroup.value!.options,
      ITEM_GROUP_INITIAL().options
    );

    widgetsStore.updateFieldOptions(
      switchItemsGroup.value!,
      initialSwitchItemsGroupOptions
    );

    switchItemsFields.value.forEach(switchItemField => {
      widgetsStore.updateFieldOptions(
        switchItemField,
        prefillEmptyOptions(switchItemField.options, ITEM_INITIAL().options)
      );
    });

    const initialEventInfoOptions = prefillEmptyOptions(
      fields.value.list_event_info.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            display: {
              layout: DisplayOrientation.VERTICAL,
              distance: 8,
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialTopLeaguesEventInfoOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_event_info.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            display: {
              layout: DisplayOrientation.VERTICAL,
              distance: 8,
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialEventDateOptions = prefillEmptyOptions(
      fields.value.list_date.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            icon: {
              _active: true,
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
              value: "ant-design:calendar-filled",
            },
          },
        },
        values: {
          padding: getDefaultSpacing(),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialTopLeaguesEventDateOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_date.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            icon: {
              _active: true,
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
              value: "ant-design:calendar-filled",
            },
          },
        },
        values: {
          padding: getDefaultSpacing(),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialFavouriteOptions = prefillEmptyOptions(
      fields.value.list_favourite_icon.field.options,
      getInitialOptions({
        data: { options: {} },
        exclude: [],
        additionalData: {
          options: {
            size: 20,
            color: getDefaultFillValue("#FFC53D"),
          },
        },
      }).options
    );

    const initialTopLeaguesFavouriteOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_favourite_icon.field.options,
      getInitialOptions({
        data: { options: {} },
        exclude: [],
        additionalData: {
          options: {
            size: 20,
            color: getDefaultFillValue("#FFC53D"),
          },
        },
      }).options
    );

    const initialLeaguesContainerOptions = prefillEmptyOptions(
      fields.value.leagues_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["fill"],
        additionalData: {
          options: {
            fillImageColor: getDefaultFillImage(),
            display: {
              layout: 4,
              distance: 16,
            },
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 16,
              top: 16,
            }),
          },
        },
      }).options
    );

    const leagueState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue(),
      };
    };

    const initialLeagueItemOptions = prefillEmptyOptions(
      fields.value.league_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["link", "spacing"],
        additionalData: {
          options: {
            ...TITLE_INITIAL().options,
            margins: getDefaultSpacing(0),
            states: {
              [State.HOVER]: leagueState(),
              [State.ACTIVE]: leagueState(),
              [State.DISABLED]: leagueState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#1677FF"),
          color: getDefaultFillValue("#ffffff"),
          theme: getDefaultTheme(13),
          padding: getDefaultSpacing(8),
          cornerRadius: getDefaultCornerRadiusValue(4),
        },
      }).options
    );

    const initialLeagueWinnerContainerOptions = prefillEmptyOptions(
      fields.value.winner_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["fill"],
        additionalData: {
          options: {
            fillImageColor: getDefaultFillImage(),
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialFilterContainerOptions = prefillEmptyOptions(
      fields.value.filter_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["fill"],
        additionalData: {
          options: {
            fillImageColor: getDefaultFillImage(),
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 16,
            }),
          },
          display: {
            layout: SportMenuLiveBet9CardLayoutMode.FIRST,
          },
          size: {
            width: getDefaultSizeValue(),
          },
        },
      }).options
    );

    const initialEventsContainerOptions = prefillEmptyOptions(
      fields.value.events_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["fill"],
        additionalData: {
          options: {
            fillImageColor: getDefaultFillImage(),
            distanceBetweenDateEvents: 20,
            distanceBetweenLeagueEvents: 16,
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialTopLeaguesEventsContainerOptions = prefillEmptyOptions(
      fields.value.top_leagues_events_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["fill"],
        additionalData: {
          options: {
            fillImageColor: getDefaultFillImage(),
            distanceBetweenDateEvents: 20,
            distanceBetweenLeagueEvents: 16,
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 16,
            }),
          },
        },
      }).options
    );

    const initialDayLabelOptions = prefillEmptyOptions(
      fields.value.day_label.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({ bottom: 12 }),
          margin: getDefaultSpacing(),
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          shadow: getDefaultShadow(),
          cornerRadius: getDefaultCornerRadiusValue(),
          resizing: ResizingType.FILL,
          alignment: Alignment.LEFT,
          _visualActive: true,
        },
      }).options
    );

    const initialTopLeaguesDayLabelOptions = prefillEmptyOptions(
      fields.value.top_leagues_day_label.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({ bottom: 12 }),
          margin: getDefaultSpacing(),
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          shadow: getDefaultShadow(),
          cornerRadius: getDefaultCornerRadiusValue(),
          resizing: ResizingType.FILL,
          alignment: Alignment.LEFT,
          _visualActive: true,
        },
      }).options
    );

    const initialLeagueNameOptions = prefillEmptyOptions(
      fields.value.league_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({ bottom: 12 }),
          margin: getDefaultSpacing(),
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          shadow: getDefaultShadow(),
          cornerRadius: getDefaultCornerRadiusValue(),
          resizing: ResizingType.FILL,
          alignment: Alignment.LEFT,
          _visualActive: true,
        },
      }).options
    );

    const initialTopLeaguesLeagueNameOptions = prefillEmptyOptions(
      fields.value.top_leagues_league_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
          padding: getDefaultSpacing({ bottom: 12 }),
          margin: getDefaultSpacing(),
          fill: getDefaultFillValue(),
          border: getDefaultBorderValue(),
          shadow: getDefaultShadow(),
          cornerRadius: getDefaultCornerRadiusValue(),
          resizing: ResizingType.FILL,
          alignment: Alignment.LEFT,
          _visualActive: true,
        },
      }).options
    );

    const initialSearchTitleOptions = prefillEmptyOptions(
      fields.value.search_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: getDefaultTheme(16),
          padding: getDefaultSpacing({ bottom: 12 }),
          decoration: [TextStyle.BOLD],
        },
      }).options
    );

    const initialHeaderOptions = prefillEmptyOptions(
      fields.value.header.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            alignment: VerticalAlignment.MIDDLE,
          },
        },
        values: {
          spacing: {
            padding: getDefaultSpacing(),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialTitleOptions = prefillEmptyOptions(
      fields.value.title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialButtonOptions = prefillEmptyOptions(
      fields.value.live_button_title.field.options,
      getInitialOptions({
        data: BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue("#389E0D"),
          iconSettings: {
            default_value: "mdi:circle",
            textColor: getDefaultFillValue("#FFFFFF"),
            iconSize: 12,
            position: Alignment.LEFT,
            gap: 5,
            _active: true,
          },
        },
      }).options
    );

    const initialLeagueWinnerTitleOptions = prefillEmptyOptions(
      fields.value.winner_league_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialWinnerCardOptions = prefillEmptyOptions(
      fields.value.winner_league_card.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            displaySettings: 1,
            horizontalSpacing: 16,
            verticalSpacing: 16,
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677FF"),
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677FF"),
          decoration: [TextStyle.BOLD],
        },
      };
    };

    const initialBetItemsOptions = prefillEmptyOptions(
      fields.value.winner_league_bet_items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue(8),
          padding: getDefaultSpacing(8),
        },
      }).options
    );

    const initialListTitleOptions = prefillEmptyOptions(
      fields.value.list_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
      }).options
    );

    const initialEventsFilterOptions = prefillEmptyOptions(
      fields.value.filter.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        additionalData: {
          options: {
            distance: 24,
            displayMode: DisplayOrientation.VERTICAL,
            visibility: "all",
            alignment: Alignment.LEFT,
          },
        },
        values: {
          fill: getDefaultFillValue("#ffffff"),
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            padding: getDefaultSpacing(16),
            margin: getDefaultSpacing({
              top: 0,
              bottom: 24,
            }),
          },
        },
      }).options
    );

    const initialEventCardsState = () => {
      return {
        card: {
          fill: getDefaultFillValue("#ffffff"),
          border: getDefaultBorderValue(),
          shadow: {
            fill: getDefaultFillValue(),
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
        },
        name: {
          color: getDefaultFillValue("#000000"),
          decoration: [],
        },
        time: {
          color: getDefaultFillValue("#000000", 65),
          icon: {
            color: getDefaultFillValue("#69B1FF"),
          },
        },
        date: {
          color: getDefaultFillValue("#000000", 65),
          icon: {
            color: getDefaultFillValue("#69B1FF"),
          },
        },
      };
    };

    const initialEventCardsOptions = prefillEmptyOptions(
      fields.value.list_container.field.options,
      getInitialOptions({
        data: {
          options: {
            card: {
              ...DESIGN_INITIAL().options,
              spacing: {
                padding: getDefaultSpacing(16),
                margin: getDefaultSpacing(),
              },
              fill: getDefaultFillValue("#ffffff"),
            },
            items: {
              distanceBetweenItems: 16,
              displayMode: DisplayOrientation.HORIZONTAL,
            },
          },
        },
        exclude: ["card.size"],
        additionalData: {
          options: {
            gridSettings: {
              grid: Grid.FIXED,
              minCardWidth: 228,
              gridFixedType: Grid.FIT,
              gap: 12,
              rowGap: 12,
              maxCardsInRow: 1,
              minCardsInRow: 1,
            },
            states: {
              [State.HOVER]: initialEventCardsState(),
              [State.ACTIVE]: initialEventCardsState(),
              [State.DISABLED]: initialEventCardsState(),
            },
          },
        },
      }).options
    );

    const initialTopLeaguesEventCardsOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_container.field.options,
      getInitialOptions({
        data: {
          options: {
            card: {
              ...DESIGN_INITIAL().options,
              spacing: {
                padding: getDefaultSpacing(16),
                margin: getDefaultSpacing(),
              },
              fill: getDefaultFillValue("#ffffff"),
            },
            items: {
              distanceBetweenItems: 16,
              displayMode: DisplayOrientation.HORIZONTAL,
            },
          },
        },
        exclude: ["card.size"],
        additionalData: {
          options: {
            gridSettings: {
              grid: Grid.FIXED,
              minCardWidth: 228,
              gridFixedType: Grid.FIT,
              gap: 12,
              rowGap: 12,
              maxCardsInRow: 1,
              minCardsInRow: 1,
            },
            states: {
              [State.HOVER]: initialEventCardsState(),
              [State.ACTIVE]: initialEventCardsState(),
              [State.DISABLED]: initialEventCardsState(),
            },
          },
        },
      }).options
    );

    const initialStartTimeDateOptions = prefillEmptyOptions(
      fields.value.list_start_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            icon: {
              value: "ant-design:clock-circle-filled",
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
            },
          },
        },
        values: {
          padding: getDefaultSpacing(),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialTopLeaguesStartTimeDateOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_start_time.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        additionalData: {
          options: {
            icon: {
              value: "ant-design:clock-circle-filled",
              size: 16,
              color: getDefaultFillValue("#69B1FF"),
            },
          },
        },
        values: {
          padding: getDefaultSpacing(),
          theme: 13,
          color: getDefaultFillValue("#000000", 65),
        },
      }).options
    );

    const initialEventsLeagueOptions = prefillEmptyOptions(
      fields.value.league_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          padding: getDefaultSpacing({ bottom: 4 }),
        },
      }).options
    );

    const initialTopLeaguesEventsLeagueOptions = prefillEmptyOptions(
      fields.value.top_leagues_league_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          padding: getDefaultSpacing({ bottom: 4 }),
        },
      }).options
    );

    const initialTeamPlayerOptions = prefillEmptyOptions(
      fields.value.list_event_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          decoration: [TextStyle.BOLD],
          padding: getDefaultSpacing(),
        },
      }).options
    );

    const initialTopLeaguesTeamPlayerOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_event_name.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          decoration: [TextStyle.BOLD],
          padding: getDefaultSpacing(),
        },
      }).options
    );

    const initialBuildButtonOptions = prefillEmptyOptions(
      fields.value.betbuilder_button.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          color: getDefaultFillValue("#1677FF"),
          cornerRadius: getDefaultCornerRadiusValue(50),
        },
      }).options
    );

    const initialTopLeaguesBuildButtonOptions = prefillEmptyOptions(
      fields.value.top_leagues_betbuilder_button.field.options,
      getInitialOptions({
        data: TEXT_BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          color: getDefaultFillValue("#1677FF"),
          cornerRadius: getDefaultCornerRadiusValue(50),
        },
      }).options
    );

    const initialListBetItemsOptions = prefillEmptyOptions(
      fields.value.list_bet_item.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            container: {
              margin: getDefaultSpacing({
                top: 12,
              }),
            },
            padding: getDefaultSpacing({
              top: 5,
              right: 8,
              bottom: 5,
              left: 8,
            }),
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue(8),
        },
      }).options
    );

    const initialTopLeaguesListBetItemsOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_bet_item.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["spacing", "size"],
        additionalData: {
          options: {
            fontFamily: getDefaultFontFamily(),
            container: {
              margin: getDefaultSpacing({
                top: 12,
              }),
            },
            padding: getDefaultSpacing({
              top: 5,
              right: 8,
              bottom: 5,
              left: 8,
            }),
            name: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [],
            },
            coef: {
              color: getDefaultFillValue("#1677FF"),
              theme: 13,
              decoration: [TextStyle.BOLD],
            },
            states: {
              [State.HOVER]: initialBetItemState(),
              [State.ACTIVE]: initialBetItemState(),
              [State.DISABLED]: initialBetItemState(),
            },
          },
        },
        values: {
          fill: getDefaultFillValue("#E6F4FF"),
          cornerRadius: getDefaultCornerRadiusValue(8),
        },
      }).options
    );

    const initialNoEventsTextOptions = prefillEmptyOptions(
      fields.value.no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          alignment: Alignment.CENTER,
        },
      }).options
    );

    const initialTopLeaguesNoEventsTextOptions = prefillEmptyOptions(
      fields.value.top_leagues_no_events_title.field.options,
      getInitialOptions({
        data: TITLE_INITIAL(),
        exclude: ["link"],
        values: {
          theme: 13,
          alignment: Alignment.CENTER,
        },
      }).options
    );

    const initialListItemMoreState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        color: getDefaultFillValue(),
      };
    };

    const initialListItemMoreOptions = prefillEmptyOptions(
      fields.value.list_item_more.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [TextStyle.BOLD],
            states: {
              [State.HOVER]: initialListItemMoreState(),
              [State.ACTIVE]: initialListItemMoreState(),
              [State.DISABLED]: initialListItemMoreState(),
            },
          },
        },
        values: {
          width: getDefaultSizeValue(ResizingType.HUG),
          fill: getDefaultFillValue("#000000", 6),
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            margin: getDefaultSpacing({
              left: 16,
            }),
            padding: getDefaultSpacing({
              top: 4,
              bottom: 4,
              left: 8,
              right: 8,
            }),
          },
        },
      }).options
    );

    const initialTopLeaguesListItemMoreOptions = prefillEmptyOptions(
      fields.value.top_leagues_list_item_more.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["size"],
        additionalData: {
          options: {
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: [TextStyle.BOLD],
            states: {
              [State.HOVER]: initialListItemMoreState(),
              [State.ACTIVE]: initialListItemMoreState(),
              [State.DISABLED]: initialListItemMoreState(),
            },
          },
        },
        values: {
          width: getDefaultSizeValue(ResizingType.HUG),
          fill: getDefaultFillValue("#000000", 6),
          cornerRadius: getDefaultCornerRadiusValue(8),
          spacing: {
            margin: getDefaultSpacing({
              left: 16,
            }),
            padding: getDefaultSpacing({
              top: 4,
              bottom: 4,
              left: 8,
              right: 8,
            }),
          },
        },
      }).options
    );

    const initialTabsOptions = prefillEmptyOptions(
      fields.value.items.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
      }).options
    );

    const initialFieldContainerState = () => {
      return {
        fill: getDefaultFillValue("#FFFFFF"),
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        text: {
          color: getDefaultFillValue("#000000"),
        },
        icon: {
          textColor: getDefaultFillValue("#000000"),
        },
        placeholder: {
          color: getDefaultFillValue("#C7C7C7"),
        },
      };
    };

    const initialSearchContainerOptions = prefillEmptyOptions(
      fields.value.search_container.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        exclude: ["fill"],
        additionalData: {
          options: {
            fillImageColor: getDefaultFillImage(),
          },
        },
      }).options
    );

    const initialSearchBoxOptions = prefillEmptyOptions(
      fields.value.search_box.field.options,
      getInitialOptions({
        data: DESIGN_INITIAL(),
        values: {
          fill: getDefaultFillValue("#ffffff"),
          spacing: {
            padding: getDefaultSpacing({
              top: 16,
              right: 16,
              bottom: 16,
              left: 16,
            }),
            margin: getDefaultSpacing(),
          },
        },
      }).options
    );

    const initialSearchFieldOptions = prefillEmptyOptions(
      fields.value.search_field.field.options,
      getInitialOptions({
        data: DROPDOWN_TITLE_INITIAL(),
        exclude: ["arrow"],
        values: {
          border: getDefaultBorderValue({
            fill: getDefaultFillValue("#000000"),
          }),
          text: {
            _active: true,
            color: getDefaultFillValue("#494949"),
            theme: 16,
            fontFamily: getDefaultFontFamily(),
            decoration: null,
            value: "Request example",
          },
          icon: {
            value: "ant-design:search-outlined",
            textColor: {
              color: "#000000",
              opacity: 100,
            },
            _active: true,
          },
          states: {
            [State.HOVER]: initialFieldContainerState(),
            [State.ACTIVE]: initialFieldContainerState(),
            [State.DISABLED]: initialFieldContainerState(),
          },
        },
        additionalData: {
          options: {
            placeholder: {
              _active: true,
              value: "Search",
              color: getDefaultFillValue("#C7C7C7"),
            },
          },
        },
      }).options
    );

    const initialSearchResultItemsState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: getDefaultFillValue("#000000"),
        },
      };
    };

    fields.value.search_result_items.field.value =
      useLocalizedValue().transformToLocalizedValue(
        "Search item"
      ).localizedValue;

    const initialSearchResultItemsOptions = prefillEmptyOptions(
      fields.value.search_result_items.field.options,
      getInitialOptions({
        data: ITEMS_GROUP_INITIAL(),
        exclude: ["itemsIcon", "margins"],
        values: {
          fill: getDefaultFillValue(),
          spaceBetween: 16,
          itemsText: {
            _active: true,
            color: getDefaultFillValue("#000000"),
            theme: 13,
            fontFamily: getDefaultFontFamily(),
            decoration: null,
          },
          states: {
            [State.HOVER]: initialSearchResultItemsState(),
            [State.ACTIVE]: initialSearchResultItemsState(),
          },
        },
      }).options
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_container.field,
      initialSearchContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_box.field,
      initialSearchBoxOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_field.field,
      initialSearchFieldOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_result_items.field,
      initialSearchResultItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.header.field,
      initialHeaderOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.title.field,
      initialTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.live_button_title.field,
      initialButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.winner_league_title.field,
      initialLeagueWinnerTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.winner_league_card.field,
      initialWinnerCardOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.winner_league_bet_items.field,
      initialBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_title.field,
      initialListTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.filter.field,
      initialEventsFilterOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_container.field,
      initialEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_container.field,
      initialTopLeaguesEventCardsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_start_time.field,
      initialStartTimeDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_start_time.field,
      initialTopLeaguesStartTimeDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.league_title.field,
      initialEventsLeagueOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_league_title.field,
      initialTopLeaguesEventsLeagueOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_event_name.field,
      initialTeamPlayerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_event_name.field,
      initialTopLeaguesTeamPlayerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_bet_item.field,
      initialListBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_bet_item.field,
      initialTopLeaguesListBetItemsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_item_more.field,
      initialListItemMoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_item_more.field,
      initialTopLeaguesListItemMoreOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.items.field,
      initialTabsOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.no_events_title.field,
      initialNoEventsTextOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_no_events_title.field,
      initialTopLeaguesNoEventsTextOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.betbuilder_button.field,
      initialBuildButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_betbuilder_button.field,
      initialTopLeaguesBuildButtonOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.leagues_container.field,
      initialLeaguesContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.league_card.field,
      initialLeagueItemOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.winner_container.field,
      initialLeagueWinnerContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.filter_container.field,
      initialFilterContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.events_container.field,
      initialEventsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_events_container.field,
      initialTopLeaguesEventsContainerOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.league_name.field,
      initialLeagueNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_league_name.field,
      initialTopLeaguesLeagueNameOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.search_title.field,
      initialSearchTitleOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.day_label.field,
      initialDayLabelOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_day_label.field,
      initialTopLeaguesDayLabelOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_event_info.field,
      initialEventInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_event_info.field,
      initialTopLeaguesEventInfoOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_date.field,
      initialEventDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_date.field,
      initialTopLeaguesEventDateOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.list_favourite_icon.field,
      initialFavouriteOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.top_leagues_list_favourite_icon.field,
      initialTopLeaguesFavouriteOptions
    );

    widgetsStore.updateFieldOptions(
      fields.value.switch.field,
      initialSwitchOptions
    );

    widgetsStore.updateWidgetOptions(widget, initialWidgetOptions);
  };

  const initFields = (): void => {
    if (!Object.keys(widget.options).length) {
      createFields();

      initItemsGroup();
      switchInitItemsGroup();
    } else {
      const customWidgetFields: IWidgetField[] = prefillCustomFields(
        widget.options,
        widget.id
      );

      let fieldsResult: Array<IWidgetField> = [];

      if (!widget.fields.find(field => field.options._custom)) {
        fieldsResult = [...widget.fields, ...customWidgetFields];
      } else {
        fieldsResult = [...widget.fields];
      }

      widgetsStore.updateWidgetFields(widget, fieldsResult);
    }

    initWidgetFieldsOptions();
  };

  const filterFields = computed<IWidgetField[]>(() => {
    return widget.fields.filter(field =>
      LIST_FILTER_FIELDS.includes(field.name)
    );
  });

  return {
    states,
    filterFields,
    DEFAULT_STATES,

    initFields,
    initWidgetFieldsOptions,

    itemsFields,
    itemsGroup,
    menuItemsOptions,

    switchItemsFields,
    switchItemsGroup,
    switchMenuItemsOptions,
  };
};
