import { nanoid } from "nanoid";
import { uuidv7 } from "uuidv7";
import isObject from "lodash/isObject";

import { configAppApi, dashboardApi, cdnApi } from "~~/services/http";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useLanguagesStore } from "~~/store/languages";
import { useMetaStore } from "~~/store/meta";
import {
  GridWithMeta,
  ITemplateResponse,
} from "~~/models/stores/grid-store.model";
import { createContentWidget } from "~~/assets/utils/widget";
import { useGridConfig } from "~~/store/grid";
import {
  IGridWidgetsData,
  IPageContentResponse,
  IPageContentWidget,
} from "~~/models/page.model";
import runWidgetMigrations from "~~/migrations/runWidgetMigrations";
import { deepCopy } from "~~/assets/utils";

export type PageItemOptions = {
  cellsOptions: IWidgetOptions;
  cssFileId: string;
  tags?: string[];
};

export type Page = {
  id: string;
  name: string;
  route: string;
  template_id: string;
  published: boolean;
  options: PageItemOptions;
};

export type PageResponse = {
  data: Page[];
};

export type CellData = {
  cellId: string;
  widgets: IPageContentWidget[];
};

export type PageContent = {
  [cellId: string]: CellData;
};

export type PageItemData = {
  options: PageItemOptions;
  pageContent: PageContent;
};

export type PagesContentData = {
  [pageId: string]: PageItemData;
};

export type PageWidgetsData = Record<
  string,
  { widgetId: string; cellId: string }[]
>;

export type WidgetWithMeta = IPageContentWidget & {
  key: string | number;
  page: any;
};

export type PageMeta = {
  id: string;
  name: string;
  options: PageItemOptions;
  template: string;
};

export type ChildrenWidgets = {
  [parentWidgetId: string]: IPageContentWidget[];
};

function series(items: any[], fn: (dataItem: any) => Promise<any>) {
  const result: Promise<any>[] = [];

  return items
    .reduce((acc, item) => {
      acc = acc.then(() => {
        return fn(item).then(res => result.push(res));
      });
      return acc;
    }, Promise.resolve())
    .then(() => result);
}

function splitToChunks(items: any[], chunkSize = 4) {
  const result = [];
  for (let i = 0; i < items.length; i += chunkSize) {
    result.push(items.slice(i, i + chunkSize));
  }
  return result;
}

function all(dataItems: any[], fn: (dataItem: any) => Promise<any>) {
  const promises = dataItems.map(item => fn(item));
  return Promise.all(promises);
}

function chunks(
  items: any[],
  fn: (dataItem: any) => Promise<any>,
  chunkSize = 2
): Promise<any> {
  let result: Promise<any>[] = [];
  const chunks = splitToChunks(items, chunkSize);

  return series(chunks, chunk => {
    return all(chunk, fn).then(res => (result = result.concat(res)));
  }).then(() => result);
}

export const useGlobalPages = (isActive: Ref<boolean>) => {
  const languagesStore = useLanguagesStore();
  const metaStore = useMetaStore();
  const gridStore = useGridConfig();

  const pagesList = ref<Page[]>([]);
  const pagesContentData = ref<PagesContentData>({});
  const pagesData = ref<Record<string, PageMeta>>({});
  const templatesData = ref<Record<string, GridWithMeta>>({});
  const pagesWithErrors = ref<Record<string, Page>>({});

  const fetchAllPages = (skipUnpublished = false): Promise<Page[]> => {
    return configAppApi
      .get(
        `/project-pages?language=${languagesStore.defaultLanguage?.codeWithRegion}`
      )
      .then((response: PageResponse) => {
        if (skipUnpublished) {
          pagesList.value = response.data.filter(page => page.published);
          return response.data;
        }
        pagesList.value = response.data;

        const allPagesData: Record<string, PageMeta> = {};

        pagesList.value.forEach(page => {
          allPagesData[page.id] = {
            id: page.id,
            name: page.name,
            options: page.options,
            template: page.template_id,
          };
        });

        pagesData.value = allPagesData;

        return response.data;
      })
      .catch(err => {
        console.error(err);
        return [];
      });
  };

  const fetchPagesContent = async () => {
    const allPagesData: Record<string, any> = {};

    const config = {
      headers: {
        "Accept-Language": languagesStore.currentLanguage?.codeWithRegion,
      },
    };

    await chunks(pagesList.value, page => {
      if (!isActive.value) {
        return Promise.resolve();
      }

      /*
        Try to fetch page content from cdn,
        if error => fetch from api
      */
      return (
        cdnApi
          .get(
            `/projects/${metaStore.projectId}/pages/${
              page.id
            }.json?query=${Date.now()}`
          )
          // .then(res => {
          //   allPagesData[page.id] = res.data;
          // })
          .catch(() => {
            return dashboardApi.get(`/pages/${page.id}/get-content`, config);
            // .then(res => {
            //   allPagesData[page.id] = res.data;
            // });
          })
          .then(response => {
            const data = (response as unknown as IPageContentResponse).data;
            if (!data?.pageId) {
              console.error("ERROR", response);
              return;
            }

            for (const cellId in data.pageContent) {
              const widgetsContentList = data.pageContent[cellId].widgets;

              data.pageContent[cellId].widgets = widgetsContentList.map(
                widget => {
                  try {
                    const migratedWidget = runWidgetMigrations(widget);

                    return migratedWidget;
                  } catch (e) {
                    console.error(
                      `Error during migration on page ${page.route}`
                    );
                    console.log(e);

                    pagesWithErrors.value[page.id] = page;

                    return widget;
                  }
                }
              );
            }

            allPagesData[page.id] = data;
          })
      );
    });

    pagesContentData.value = allPagesData;

    return allPagesData;
  };

  const fetchTemplatesData = async (): Promise<
    Record<string, GridWithMeta>
  > => {
    const allTemplatesData: Record<string, GridWithMeta> = {};
    const templates: string[] = [];

    /*
      Get unique templates list
    */
    for (const pageId in pagesData.value) {
      const page = pagesData.value[pageId];

      if (templates.includes(page.template)) {
        continue;
      }

      templates.push(page.template);
    }

    /*
      Fetch templates details
    */

    for await (const templateId of templates) {
      if (!isActive.value) {
        break;
      }

      await dashboardApi
        .get(`/template/${templateId}`)
        .then((response: ITemplateResponse) => {
          allTemplatesData[templateId] = response.data;
        });
    }

    templatesData.value = allTemplatesData;
    return allTemplatesData;
  };

  const fetchPagesData = async (): Promise<Record<string, PageMeta>> => {
    const allPagesData: Record<string, PageMeta> = {};

    await chunks(pagesList.value, (page: any) => {
      if (!isActive.value) {
        return Promise.resolve();
      }

      return dashboardApi.get(`/pages/${page.id}`).then(res => {
        allPagesData[page.id] = res.data;
      });
    });

    // for await (const page of pagesList.value) {
    //   if (!isActive.value) {
    //     break;
    //   }

    //   await dashboardApi.get(`/pages/${page.id}`).then(res => {
    //     allPagesData[page.id] = res.data;
    //   });
    // }

    pagesData.value = allPagesData;
    return allPagesData;
  };

  /* 
    Flatten list of global widgets children
  */
  const getSimilarWidgetsChildren = (
    similarWidgets: IPageContentWidget[],
    allChildrenWidgets: ChildrenWidgets,
    parentWidgetId?: string
  ): ChildrenWidgets => {
    const childrenWidgets: ChildrenWidgets = {};

    /* 
      Run through widgets with same _tag OR
      children of such widget
    */
    similarWidgets.forEach(widget => {
      const widgetId = widget.id as string;

      /* 
        If widget is not parent(no children widgets) - skip
      */
      if (!allChildrenWidgets[widgetId]) {
        return;
      }

      const rootContainerId = parentWidgetId || widgetId;

      /* 
        If current similar widget has children
      */

      const result = getSimilarWidgetsChildren(
        allChildrenWidgets[widgetId],
        allChildrenWidgets,
        rootContainerId
      );

      childrenWidgets[rootContainerId] = [
        /* 
          Result from previous iterations
        */
        ...(childrenWidgets[rootContainerId] || []),

        /* 
          Children of current widget
        */
        ...allChildrenWidgets[widgetId],

        /* 
          Children of current widget children(recursion)
        */
        ...(result[rootContainerId] || []),
      ];
    });

    return childrenWidgets;
  };

  const findSimilarTagWidgetsInPage = (
    widgetsList: IPageContentWidget[],
    widget: IWidgetWithFields
  ): {
    similar: IPageContentWidget[];
    children: Record<string, IPageContentWidget[]>;
  } => {
    if (!widget.options._tag) {
      return { similar: [], children: {} };
    }

    const allChildrenWidgets: ChildrenWidgets = {};
    const similarWidgets: IPageContentWidget[] = [];

    widgetsList.forEach(currWidget => {
      if (currWidget.parentId) {
        if (!allChildrenWidgets[currWidget.parentId]) {
          allChildrenWidgets[currWidget.parentId] = [];
        }

        allChildrenWidgets[currWidget.parentId].push(currWidget);
      }

      if (currWidget.options._tag === widget.options._tag) {
        similarWidgets.push(currWidget);
      }
    });

    return {
      similar: similarWidgets,
      children: getSimilarWidgetsChildren(similarWidgets, allChildrenWidgets),
    };
  };

  const findSimilarTagWidgetsInGrid = (
    pageContent: PagesContentData,
    widget: IWidgetWithFields | undefined | null
  ): {
    similar: WidgetWithMeta[];
    children: Record<string, IPageContentWidget[]>;
  } => {
    if (!widget) {
      console.log("Widget is not selected");
      return { similar: [], children: {} };
    }

    const similarWidgets = [] as WidgetWithMeta[];
    let childrenWidgets = {};

    for (const pageId in pageContent) {
      const page = pageContent[pageId];

      for (const cellId in page.pageContent) {
        const { similar, children } = findSimilarTagWidgetsInPage(
          page.pageContent[cellId].widgets,
          widget
        );

        childrenWidgets = {
          ...childrenWidgets,
          ...children,
        };

        similarWidgets.push(
          ...similar.map(currWidget => {
            return {
              ...currWidget,
              page,
              key: `${pageId}==${cellId}==${currWidget.id}`,
            };
          })
        );
      }
    }

    return {
      similar: similarWidgets,
      children: childrenWidgets,
    };
  };

  const findPageIdByWidget = (
    widget: IWidgetWithFields | IPageContentWidget
  ): string | null => {
    for (const pageId in pagesContentData.value) {
      const page = pagesContentData.value[pageId];

      for (const cellId in page.pageContent) {
        const widgetFound = page.pageContent[cellId].widgets.find(
          pageWidget => pageWidget.id === widget.id
        );
        if (widgetFound) {
          return pageId;
        }
      }
    }

    return null;
  };

  interface IApplyOperationParams {
    isApplyDataForCopiedWidgets: boolean;
  }

  /*
    Remove all children and push new 
    ones from current global widget children list. We can not 
    just replace old ones, since in current global widget 
    some children may be removed or added
  */
  const replaceChildWidgetsInContent = (data: {
    cellData: CellData;
    currentWidgetChildren: IPageContentWidget[];
    globalWidgetChildren: Record<string, IPageContentWidget>;
    newReplacedWidgetId: string;
    cellId: string;
  }): CellData => {
    const {
      cellData,
      currentWidgetChildren,
      globalWidgetChildren,
      newReplacedWidgetId,
      cellId,
    } = data;

    currentWidgetChildren.forEach(widget => {
      const widgetIdx = cellData.widgets.findIndex(
        currWidget => currWidget.id === widget.id
      );

      if (widgetIdx > -1) {
        cellData.widgets.splice(widgetIdx, 1);
      }
    });

    const idsMapper: Record<string, string> = {};

    const mapGlobalWidgetIdToNewId = (
      globalChildId: string,
      newChildId: string
    ) => {
      idsMapper[globalChildId] = newChildId;
    };

    Object.keys(globalWidgetChildren).forEach(childId => {
      const child = globalWidgetChildren[childId];
      const newWidgetId = uuidv7();
      mapGlobalWidgetIdToNewId(child.id as string, newWidgetId);
      const widgetCopy = JSON.parse(JSON.stringify(child));

      cellData.widgets.push({
        ...widgetCopy,
        id: newWidgetId,
        cellId,
      });
    });

    Object.keys(globalWidgetChildren).forEach(childId => {
      const child = globalWidgetChildren[childId];
      const generatedWidgetId = idsMapper[child.id as string];

      const newWidgetInList = cellData.widgets.find(
        curr => curr.id === generatedWidgetId
      );

      if (!newWidgetInList) {
        return;
      }

      newWidgetInList.parentId =
        idsMapper[newWidgetInList.parentId as string] || newReplacedWidgetId;
    });

    return cellData;
  };

  /*
    Replace widget with same tag on
    new widget(currently selected)
  */
  const replaceWidgetInContent = (data: {
    widgetData: IPageContentWidget;
    pageData: PageItemData;
    cellId: string;
    widgetId: string;
    operationParams: IApplyOperationParams;
    /* 
      Children of current(selected) widget
    */
    currentWidgetChildren: IPageContentWidget[];
    /* 
      Children of global widget(primary)
    */
    globalWidgetChildren: Record<string, IPageContentWidget>;
  }) => {
    const {
      widgetData,
      pageData,
      cellId,
      widgetId,
      currentWidgetChildren,
      globalWidgetChildren,
    } = data;

    const newWidgetId = uuidv7();

    const cellData = replaceChildWidgetsInContent({
      newReplacedWidgetId: newWidgetId,
      cellData: pageData.pageContent[cellId],
      currentWidgetChildren,
      globalWidgetChildren,
      cellId,
    });

    const widgetToReplaceIndex = cellData.widgets.findIndex(
      currWidget => currWidget.id === widgetId
    );

    if (widgetToReplaceIndex < 0) {
      return pageData;
    }

    /* 
      Widget to replace
    */
    const oldWidget = cellData.widgets[widgetToReplaceIndex];

    /* 
      Global widget(primary)
    */
    const widgetCopy = JSON.parse(JSON.stringify(widgetData));

    widgetCopy.id = newWidgetId;
    widgetCopy.parentId = oldWidget.parentId;
    widgetCopy.cellId = oldWidget.cellId;
    widgetCopy.position = oldWidget.position;

    const oldBindingParams = deepCopy(oldWidget.options.bindingParams);

    if (
      data.operationParams.isApplyDataForCopiedWidgets &&
      isObject(widgetCopy.options.bindingParams) &&
      Object.keys(widgetCopy.options.bindingParams).length
    ) {
      Object.keys(widgetCopy.options.bindingParams).forEach(paramName => {
        oldWidget.options.bindingParams[paramName] =
          widgetCopy.options.bindingParams[paramName];

        if (widgetCopy.options.bindingParams[paramName]?.children) {
          oldWidget.options.bindingParams[paramName].children = {};
        }
      });
    } else {
      widgetCopy.options.bindingParams = oldBindingParams;
    }

    cellData.widgets[widgetToReplaceIndex] = widgetCopy;

    return pageData;
  };

  /*
    Run through selected items.
    Each item is a string with meta info,
    sepated with "=="
    (pageId==cellId==widgetId)

    Gather widgets by page(if page contains few widgets with same tag)
  */
  const getWidgetsByPage = (idsList: string[]): PageWidgetsData => {
    const widgetsByPage = idsList
      .filter(currIdItem => {
        const [pageId] = currIdItem.split("==");

        if (pagesWithErrors.value[pageId]) {
          return false;
        }

        return true;
      })
      .reduce((result: PageWidgetsData, currIdItem) => {
        const [pageId, cellId, widgetId] = currIdItem.split("==");

        if (!result[pageId]) {
          result[pageId] = [];
        }

        result[pageId].push({
          widgetId,
          cellId,
        });

        return result;
      }, {});

    return widgetsByPage;
  };

  /* 
    Generate content view of children widgets
    for current selected global widget(primary one)
  */
  const generatePrimaryWidgetChildrenContent = (
    widget: IWidgetWithFields
  ): Record<string, IPageContentWidget> => {
    if (!widget.options._children) {
      return {};
    }

    return widget.options._children.reduce((result, child) => {
      const widgetContentData = createContentWidget(
        child,
        child.cell_id! as string
      );

      return {
        ...result,
        ...generatePrimaryWidgetChildrenContent(child),
        [child.id]: widgetContentData,
      };
    }, {});
  };

  const applyWidgetChangesToList = async (
    widget: IWidgetWithFields,
    idsList: string[],
    operationParams: IApplyOperationParams,
    /* 
      Children of selected widget
    */
    selectedWidgetsChildren: ChildrenWidgets
  ) => {
    const currContentData: PagesContentData = JSON.parse(
      JSON.stringify(pagesContentData.value)
    );

    /*
      Create widget of format:
      {
        id: ...,
        content: {[fieldName]: [fieldDetails]}
      }

      (Instead of {fields: [...]})
    */
    const formattedWidgetData = createContentWidget(
      widget,
      widget.cell_id! as string
    );

    /* 
      GLOBAL(current selected) container widget children formatted content
    */
    const childrenWidgetsData = generatePrimaryWidgetChildrenContent(widget);

    const widgetsByPage: PageWidgetsData = getWidgetsByPage(idsList);

    for await (const pageId of Object.keys(widgetsByPage)) {
      if (!isActive.value) {
        break;
      }

      const pageData = pagesData.value[pageId];
      if (!pageData) {
        throw new Error(`pageData not found for pageID: ${pageId}`);
      }

      const templateData = templatesData.value[pageData.template];
      if (!templateData) {
        throw new Error(
          `templatesData not found for pageID: ${pageId}, templateId: ${pageData.template}`
        );
      }

      const widgetItems = widgetsByPage[pageData.id];
      if (!widgetItems) {
        throw new Error(`widgetItems not found for pageID: ${pageData.id}`);
      }

      widgetItems.forEach(widgetItem => {
        const { widgetId, cellId } = widgetItem;

        /* 
          Widgets children list of current widget
        */
        const children = selectedWidgetsChildren[widgetId] || [];

        /*
          Replace similar widget inside specified cell
        */
        currContentData[pageId] = replaceWidgetInContent({
          widgetData: formattedWidgetData,
          pageData: currContentData[pageId],
          widgetId: widgetId,
          cellId: cellId,
          operationParams,
          currentWidgetChildren: children,
          globalWidgetChildren: childrenWidgetsData,
        });
      });

      const pageContent = currContentData[pageId];

      const cellsGrid = templateData.grid;

      const cssFileId = nanoid();

      const pageContentData: IGridWidgetsData = {
        templateId: pageData.template,
        pageContent: pageContent.pageContent,
      };

      const generateCss = async () => {
        try {
          const res = gridStore.generateWidgetsGridCss({
            gridLayout: cellsGrid,
            page: pageData,
            widgetsGridData: pageContentData,
            cssFileId,
            cellsOptions: pageContent.options.cellsOptions,
          });

          return Promise.resolve(res);
        } catch (e) {
          return Promise.reject(e);
        }
      };

      // const currPage = pagesList.value.find(
      //   pageValue => pageValue.id === pageData.id
      // );

      // pagesWithErrors.value[pageData.id] = currPage!;

      try {
        const gridCss = await generateCss();

        await gridStore.saveContentPage({
          pageId: pageId,
          widgetsData: pageContentData,
          options: pageContent.options,
          cellsOptions: pageContent.options.cellsOptions,
          cssFileId: cssFileId,
        });

        await gridStore.saveWidgetsGridCss(gridCss);

        await new Promise(resolve => {
          /*
              Wait 7sec after page save
            */
          setTimeout(() => {
            resolve(true);
          }, 7000);
        });
      } catch (e) {
        const currPage = pagesList.value.find(
          pageValue => pageValue.id === pageData.id
        );

        console.error(`Error during save on page ${currPage?.route}`);
        console.log(e);

        pagesWithErrors.value[pageData.id] = currPage!;
      }
    }
  };

  return {
    pagesContentData,
    pagesData,
    templatesData,
    pagesList,
    pagesWithErrors,

    fetchAllPages,
    fetchPagesContent,
    fetchPagesData,
    fetchTemplatesData,

    findPageIdByWidget,
    findSimilarTagWidgetsInGrid,

    applyWidgetChangesToList,
  };
};
