import {
  applyChangesToFields,
  findSimilaritiesInFields,
  applyChangesToDataParams,
  SourceValue,
  applyChangesToBreadcrumbs,
  findDataParamsSimilarities,
  findBreadcrumbsSimilarities,
} from "~~/components/config/helpers";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ReplaceMode } from "~~/components/config/helpers";

const applyChangesToWidget = (
  sourceValue: SourceValue,
  widget: IWidgetWithFields,
  replaceMode: ReplaceMode
): void => {
  if (replaceMode === ReplaceMode.VALUE) {
    applyChangesToFields(sourceValue, widget.fields, replaceMode);
    return;
  }

  applyChangesToDataParams(widget, sourceValue);

  applyChangesToBreadcrumbs(widget, sourceValue);

  applyChangesToFields(sourceValue, widget.fields, replaceMode);
};

const findUrlSimilaritiesInWidget = (
  sourceValue: SourceValue,
  widget: IWidgetWithFields,
  replaceMode: ReplaceMode
): boolean => {
  const hasSimilarities = findSimilaritiesInFields(
    sourceValue,
    widget.fields,
    replaceMode
  );

  if (findDataParamsSimilarities(widget, sourceValue)) {
    return true;
  }

  if (findBreadcrumbsSimilarities(widget, sourceValue)) {
    return true;
  }

  return hasSimilarities;
};

const findContentSimilaritiesInWidget = (
  sourceValue: SourceValue,
  widget: IWidgetWithFields,
  replaceMode: ReplaceMode
): boolean => {
  return findSimilaritiesInFields(sourceValue, widget.fields, replaceMode);
};

const findSimilaritiesInWidget = (
  sourceValue: SourceValue,
  widget: IWidgetWithFields,
  replaceMode: ReplaceMode
): boolean => {
  if (replaceMode === ReplaceMode.VALUE) {
    return findContentSimilaritiesInWidget(sourceValue, widget, replaceMode);
  }

  return findUrlSimilaritiesInWidget(sourceValue, widget, replaceMode);
};

export const useContentPageReplace = () => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const findSimilaritiesInPage = (
    value: SourceValue,
    widgets: IWidgetWithFields[],
    replaceMode: ReplaceMode
  ): IWidgetWithFields[] => {
    return widgets.filter(widget => {
      const hasSimilarities = findSimilaritiesInWidget(
        value,
        widget,
        replaceMode
      );

      return hasSimilarities;
    });
  };

  const applyChangesToPage = (
    widgets: IWidgetWithFields[],
    value: SourceValue,
    replaceMode: ReplaceMode
  ): number => {
    widgetSettingsStore.setActiveElement(null);
    const similarWidgets = findSimilaritiesInPage(value, widgets, replaceMode);

    for (const widget of similarWidgets) {
      applyChangesToWidget(value, widget, replaceMode);
    }

    return similarWidgets.length;
  };

  return {
    applyChangesToPage,
    findSimilaritiesInPage,
  };
};
