import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultShadow,
  getDefaultSpacing,
} from "~~/constants/configs/common/widget-initial";
import {
  Alignment,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 104;

const v104WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "SportsBookPreMatchFenixMobileWidget" &&
    !widget.content.league_name.options.fill
  ) {
    widget.content.league_name.options = {
      ...widget.content.league_name.options,
      decoration: getDefaultDecoration(),
      padding: getDefaultSpacing({ bottom: 12 }),
      margin: getDefaultSpacing(),
      fill: getDefaultFillValue(),
      border: getDefaultBorderValue(),
      shadow: getDefaultShadow(),
      cornerRadius: getDefaultCornerRadiusValue(),
      resizing: ResizingType.FILL,
      alignment: Alignment.LEFT,
    };
  }

  if (
    widget.name === "SportsBookPreMatchFenixMobileWidget" &&
    !widget.content.top_leagues_league_name.options.fill
  ) {
    widget.content.top_leagues_league_name.options = {
      ...widget.content.top_leagues_league_name.options,
      decoration: getDefaultDecoration(),
      padding: getDefaultSpacing({ bottom: 12 }),
      margin: getDefaultSpacing(),
      fill: getDefaultFillValue(),
      border: getDefaultBorderValue(),
      shadow: getDefaultShadow(),
      cornerRadius: getDefaultCornerRadiusValue(),
      resizing: ResizingType.FILL,
      alignment: Alignment.LEFT,
    };
  }

  return widget;
};
export default v104WidgetMigration;
