import type { Ref } from "vue";

import {
  ICustomField,
  IWidgetField,
  type IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { size } from "~~/constants/configs/common/size-config";
import { useFlexibleImageControls } from "~~/composables/widgets/common/useFlexibleCardWidgetControls";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";

import { useButtonControls } from "../card/useButtonControls";
import { useButtonsGroupControls } from "../card/useButtonsGroupControls";
import { useTitleControls } from "../text-slider/useTitleControls";
import { useTextSliderWidgetControls } from "../text-slider/useTextSliderWidgetControls";
import { useWidgetFields } from "../useWidgetFields";

import { useSlideControls } from "./useSlideControls";

export const useBannerSliderWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = computed(() => {
    return [
      generateDropdownItem("Slide", "slide", "slide"),
      generateDropdownItem("Image", "image"),
      generateDropdownItem("Title", "title", "title"),
      generateDropdownItem("Tile link states", "linkStates", "linkStates"),
      generateDropdownItem("Description", "description_1", "description_1"),
      generateDropdownItem("Buttons group", "buttons_group"),
      generateDropdownItem("Button", "button_1"),
      generateDropdownItem("Button", "button_2"),
    ];
  });

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Navigation", "navigation"),
      generateTabItem("Data", "data"),
    ],
    slide: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    buttons_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    button_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const {
    widgetDesignControls,
    widgetContentControls,
    widgetNavigationControls,
  } = useTextSliderWidgetControls(widget);

  const { slideDesignControls, slideContentControls, slideStatesControls } =
    useSlideControls(widget, states, emit);

  const bannerSliderSlideContentControls = computed(() => {
    return [
      size({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
        keyNames: {
          width: "options.slideOptions.size.width",
          height: "options.slideOptions.size.height",
          alignment: "options.slideOptions.alignment",
        },
      }),
      ...slideContentControls.value,
    ];
  });

  const { titleControls, titleLinkStatesControls } = useTitleControls(
    states,
    emit,
    ["ConfigAlignment"]
  );

  const buttonState = computed<string>(() => {
    return states.value[selectedField.value?.name || ""];
  });

  const currentSlideFields = computed(() => {
    const currentSlide = (
      fields.value.items.field.value as ICustomField[]
    ).find(slideField =>
      slideField.fields?.some(f => f.id === selectedField.value?.id)
    );
    if (!currentSlide) return null;

    return useWidgetFields(currentSlide).value;
  });

  const { buttonsGroupDesignControls, buttonsGroupContentControls } =
    useButtonsGroupControls(widget, currentSlideFields, ["ConfigAlignment"]);

  const { buttonStatesControls, buttonDesignControls, buttonContentControls } =
    useButtonControls({
      state: buttonState,
      stateHandler: (state: State) => {
        emit("update-state", {
          state: selectedField.value?.name,
          value: state,
        });
      },
    });

  const { flexibleImageControls } = useFlexibleImageControls(
    selectedField as Ref<IWidgetField>
  );

  const imageControls = computed(() => {
    return [
      {
        componentPath: "ConfigMediaFileUpload",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          active: selectedField.value!.options._active,
          attribute: "items.image",
        },
      },
      ...flexibleImageControls.value,
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        section: generateContentSectionName("SEO settings"),
        controls: [
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "options._seo.title",
            valueSource: selectedField.value,
            options: {
              label: "Title for search engines",
              placeholder: "Add title text here",
            },
          },
          {
            componentPath: "content/ConfigTextInput",
            valuePath: "options._seo.alt",
            valueSource: selectedField.value,
            options: {
              label: "ALT text",
              placeholder: "Add alt text here",
            },
          },
        ],
      },
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options._isImagePreload",
        valueSource: selectedField.value,
        options: {
          label: "Should image preload",
          items: [
            { label: "Yes", value: true },
            { label: "No", value: false },
          ],
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,
    widgetNavigationControls,

    slideDesignControls,
    bannerSliderSlideContentControls,
    slideStatesControls,

    titleControls,
    titleLinkStatesControls,

    buttonsGroupDesignControls,
    buttonsGroupContentControls,

    buttonDesignControls,
    buttonStatesControls,
    buttonContentControls,

    imageControls,
  };
};
