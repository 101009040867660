import { BASE_MEDIA_URL } from "~~/constants";

export default function useImageCdnChange(value: string): string {
  if (!value) return "";

  if (typeof value === "string" && !value.startsWith("http")) return value;

  try {
    const imageUrl = new URL(value);
    const baseMediaUrl = new URL(BASE_MEDIA_URL());

    imageUrl.hostname = baseMediaUrl.hostname;

    return imageUrl.toString();
  } catch (error) {
    console.error(
      "Error updating image URL:",
      error,
      "With value: ",
      value,
      "Base media URL",
      BASE_MEDIA_URL
    );
    return value;
  }
}
