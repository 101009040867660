<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <VideoWidgetSettings :widget="widget" />

    <div class="video-widget">
      <WidgetField
        :widget="widget"
        :field="fields.video.field"
      >
        <video
          class="video-widget__element"
          :style="videoStyles"
          type="video/mp4"
        >
          <source :src="videoSrc" />
        </video>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import type { ICell } from "~~/models/grid.interface";
import type { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useVideoWidgetInit } from "~~/composables/widgets/video/useVideoWidgetInit";
import useImageCdnChange from "~~/composables/useImageCdnChange";
import { useWidgetStyle } from "~~/components/composables/widgets/useWidgetStyle";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { fields, init } = useVideoWidgetInit(toRef(props.widget));

init();

const videoStyles = computed(() => {
  if (!videoSrc.value) {
    return Object.assign(useWidgetStyle(fields.value.video.field).value, {
      opacity: "0",
    });
  }
  return useWidgetStyle(fields.value.video.field).value;
});

const { getLocalizedValue } = useLocalizedValue();

const videoSrc = computed<string>(() => {
  const localizedValue = getLocalizedValue.value(
    fields.value.video.field.value
  );

  return useImageCdnChange(localizedValue);
});
</script>

<style lang="scss">
.video-widget {
  position: relative;

  &__element {
    display: block;
    width: 100%;
  }
}
</style>
