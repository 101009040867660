import { handleActionSuccess } from "~~/helpers/handle-action-success";
import { handleActionError } from "~~/helpers/handle-action-error";
import { SUCCESSFULLY_UPDATED } from "~~/constants/notification-messages";
import { useMetaStore } from "~~/store/meta";
import { useContentAllPagesReplace } from "~~/components/config/composables/useContentAllPagesReplace";
import { useContentPageReplace } from "~~/components/config/composables/useContentPageReplace";
import { useGridConfig } from "~~/store/grid";
import { useLanguagesStore } from "~~/store/languages";
import { ILanguage } from "~~/models/widgets/languages.interface";
import { ReplaceMode } from "~~/components/config/helpers";

type SearchLangValue = {
  search: string;
  change: string;
  searchValid: string | boolean;
  changeValid: string | boolean;
};

const cols = [
  {
    key: "lang",
    dataIndex: "lang",
    title: "Language",
  },
  {
    key: "search",
    dataIndex: "search",
    title: "Search",
  },
  {
    key: "change",
    dataIndex: "change",
    title: "Change to",
  },
];

const similarTableColumns = [
  {
    title: "Page",
    dataIndex: "name",
    key: "name",
  },
];

type Params = {
  emit: any;
  validate?: (value?: string) => string | boolean;
  replaceMode: ReplaceMode;
};

export const useContentGlobal = (params: Params) => {
  const { emit, validate, replaceMode } = params;

  const metaStore = useMetaStore();
  const gridStore = useGridConfig();
  const languagesStore = useLanguagesStore();

  const {
    findAllPagesSimilarities,
    applyChangesToAllPages,
    similarPages,
    listLoading,
    pagesWithErrors,
  } = useContentAllPagesReplace();
  const { applyChangesToPage } = useContentPageReplace();

  const availableLangs = computed<ILanguage>(() => {
    return languagesStore.availableLanguages;
  });

  const mode = ref("single");
  const loading = ref<boolean>(false);
  const isWorkingTab = ref<boolean>(false);
  const selected = ref<string[]>([]);
  const langsSearchData = ref<Record<string, SearchLangValue>>({});

  const tableData = computed(() => {
    return Object.keys(langsSearchData.value).map(langName => {
      const langData = availableLangs.value[langName];

      return {
        lang: langData.name,
        langKey: langName,
      };
    });
  });

  const onSelectChange = (changableRowKeys: string[]): void => {
    selected.value = changableRowKeys;
  };

  const rowSelection = computed<any>(() => {
    return {
      selectedRowKeys: unref(selected),
      onChange: onSelectChange,
      hideDefaultSelections: true,
      getCheckboxProps: (record: any) => ({
        name: record.name,
      }),
    };
  });

  const ignoredPagesWithSimilarWidgets = computed<string[]>(() => {
    return Object.keys(pagesWithErrors.value).filter(pageId => {
      return similarPages.value.find(page => page.id === pageId);
    });
  });

  const submitDisabled = computed<boolean>(() => {
    const withErrors = Object.keys(langsSearchData.value).filter(langKey => {
      if (typeof langsSearchData.value[langKey].changeValid === "string") {
        return true;
      }

      if (typeof langsSearchData.value[langKey].searchValid === "string") {
        return true;
      }

      return false;
    });

    return Boolean(withErrors.length);
  });

  const handleFetchAllClick = async () => {
    if (!isWorkingTab.value) {
      const globalStatus = await metaStore.fetchGlobalStatus();
      metaStore.setGlobalProcessState(globalStatus);

      if (metaStore.globalProcessIsRunning) {
        loading.value = false;
        handleActionError("Global proccess is busy");

        return false;
      }

      metaStore.setGlobalProcessState(true);
      metaStore.updateGlobalStatus(true);
      metaStore.runUpdateWorkingStatusInterval();
      isWorkingTab.value = true;
    }

    const similar = await findAllPagesSimilarities(
      langsSearchData.value,
      replaceMode
    );

    selected.value = similar.map(page => page.id);
  };

  const handleApplyAllClick = async () => {
    loading.value = true;

    try {
      const selectedPages = similarPages.value.filter(page =>
        selected.value.includes(page.id)
      );

      await applyChangesToAllPages(
        langsSearchData.value,
        selectedPages,
        replaceMode
      );
      handleActionSuccess(SUCCESSFULLY_UPDATED);
      emit("close");
    } catch (e) {
      handleActionError("Something went wrong");
    }

    loading.value = false;
  };

  const handleApplyToPageClick = async () => {
    loading.value = true;

    const widgetsCount = applyChangesToPage(
      gridStore.pageWidgets,
      langsSearchData.value,
      replaceMode
    );

    handleActionSuccess(
      `Successfully updated. ${widgetsCount} widgets were affected`
    );
    emit("close");

    loading.value = false;
  };

  const handleInputUpdate = (
    value: string,
    key: "search" | "change",
    langKey: string
  ): void => {
    langsSearchData.value[langKey][key] = value;

    if (validate) {
      langsSearchData.value[langKey][`${key}Valid`] = validate(value);
    }
  };

  onMounted(() => {
    langsSearchData.value = Object.keys(availableLangs.value).reduce(
      (result, langName) => {
        return {
          ...result,
          [langName]: {
            search: "",
            change: "",
            searchValid: true,
            changeValid: true,
          },
        };
      },
      {}
    );
  });

  onBeforeUnmount(() => {
    if (isWorkingTab.value) {
      metaStore.updateGlobalStatus(false);

      metaStore.stopWorkingStatusInterval();
    }
  });

  return {
    handleApplyToPageClick,
    handleApplyAllClick,
    handleFetchAllClick,
    handleInputUpdate,

    similarPages,
    listLoading,
    pagesWithErrors,
    loading,
    mode,
    tableData,
    rowSelection,
    cols,
    similarTableColumns,
    ignoredPagesWithSimilarWidgets,
    langsSearchData,
    submitDisabled,
  };
};
