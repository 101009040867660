<template>
  <div v-if="isEnvLoaded">
    <router-view></router-view>
    <CommonNotifications />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount } from "vue";

import { useProject } from "~~/composables/useProject";
import { DEV_PORT } from "~~/constants";
import { loadEnvFile } from "~~/envLoader";

const { saveProjectId, saveInterfaceLang, useHotkeys } = useProject();

const getDevLink = (): void => {
  if (import.meta.env.PROD) {
    const devLink = location.href
      .replace("grid", "local")
      .replace(location.pathname, `:${DEV_PORT}${location.pathname}`);
    navigator.clipboard.writeText(devLink).then(
      () => {
        alert("Dev link generated and copied");
      },
      err => {
        console.error("Copying error: ", err);
      }
    );
  }
};

useHotkeys({
  "ctrl+g": getDevLink,
  "cmd+g": getDevLink,
});

const isEnvLoaded = ref(false);

onBeforeMount(async () => {
  await loadEnvFile();

  isEnvLoaded.value = true;

  saveProjectId();
  saveInterfaceLang();
  window.showReleaseVersion();
});
</script>
