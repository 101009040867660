import { nanoid } from "nanoid";

import {
  useGlobalPages,
  type Page,
  PageMeta,
} from "~~/composables/widgets-config/useGlobalPages";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";
import {
  ICustomField,
  IWidgetField,
} from "~~/models/widgets/widget.core/widget.model";
import {
  IContentField,
  IGridWidgetsData,
  IPageContentWidget,
} from "~~/models/page.model";
import {
  findSimilaritiesInFields,
  compareMultilangValues,
  type SourceValue,
  changeSimilarMultilangValues,
  applyChangesToFields,
  applyChangesToDataParams,
  applyChangesToBreadcrumbs,
  findBreadcrumbsSimilarities,
  findDataParamsSimilarities,
  ReplaceMode,
  findFieldContentSimilarities,
  applyContentChangesToField,
} from "~~/components/config/helpers";
import { useGridConfig } from "~~/store/grid";
import { CellId, Grid, ICellOptions } from "~~/models/grid.interface";
import { deepCopy } from "~~/assets/utils";

const findSimilaritiesInContent = (
  sourceValue: SourceValue,
  contentFields: Record<string, IContentField>,
  replaceMode: ReplaceMode
): boolean => {
  for (const fieldName in contentFields) {
    const field = contentFields[fieldName];

    if (field.type === WIDGET_FIELD_TYPES.ARRAY_ELEMENT) {
      const childFields = (field.value as [ICustomField])[0]?.fields;

      const hasSimilarities = findSimilaritiesInFields(
        sourceValue,
        childFields as IWidgetField[],
        replaceMode
      );

      if (hasSimilarities) {
        return true;
      }

      continue;
    }

    if (replaceMode === ReplaceMode.VALUE) {
      if (findFieldContentSimilarities(sourceValue, field as IWidgetField)) {
        return true;
      }
    }

    if (replaceMode === ReplaceMode.URL) {
      if (compareMultilangValues(sourceValue, field.options.link?.value)) {
        return true;
      }

      if (
        compareMultilangValues(sourceValue, field.options.link?.authorizeValue)
      ) {
        return true;
      }
    }
  }

  return false;
};

const findSimilaritiesInContentWidget = (
  sourceValue: SourceValue,
  widget: IPageContentWidget,
  replaceMode: ReplaceMode
): boolean => {
  if (replaceMode === ReplaceMode.VALUE) {
    return findSimilaritiesInContent(sourceValue, widget.content, replaceMode);
  }

  if (findSimilaritiesInContent(sourceValue, widget.content, replaceMode)) {
    return true;
  }

  if (findDataParamsSimilarities(widget, sourceValue)) {
    return true;
  }

  if (findBreadcrumbsSimilarities(widget, sourceValue)) {
    return true;
  }

  return false;
};

const applyChangesToContentFields = (
  sourceValue: SourceValue,
  contentFields: Record<string, IContentField>,
  replaceMode: ReplaceMode
): Record<string, IContentField> => {
  for (const fieldName in contentFields) {
    const field = contentFields[fieldName];

    if (field.type === WIDGET_FIELD_TYPES.ARRAY_ELEMENT) {
      const childFields = (field.value as [ICustomField])[0]?.fields;

      applyChangesToFields(
        sourceValue,
        childFields as IWidgetField[],
        replaceMode
      );

      continue;
    }

    if (replaceMode === ReplaceMode.VALUE) {
      applyContentChangesToField(sourceValue, field as IWidgetField);
    }

    if (replaceMode === ReplaceMode.URL) {
      if (field.options.link?.value) {
        field.options.link.value = changeSimilarMultilangValues(
          sourceValue,
          field.options.link.value
        );
      }

      if (field.options.link?.authorizeValue) {
        field.options.link.authorizeValue = changeSimilarMultilangValues(
          sourceValue,
          field.options.link.authorizeValue
        );
      }
    }
  }

  return contentFields;
};

const applyChangesToContentWidget = (
  sourceValue: SourceValue,
  widget: IPageContentWidget,
  replaceMode: ReplaceMode
) => {
  if (replaceMode === ReplaceMode.URL) {
    applyChangesToDataParams(widget, sourceValue);

    applyChangesToBreadcrumbs(widget, sourceValue);
  }

  widget.content = applyChangesToContentFields(
    sourceValue,
    widget.content,
    replaceMode
  );

  return widget;
};

export const useContentAllPagesReplace = () => {
  const gridStore = useGridConfig();

  const {
    pagesContentData,
    pagesData,
    templatesData,
    pagesList,
    pagesWithErrors,

    fetchAllPages,
    fetchPagesContent,
    fetchTemplatesData,
  } = useGlobalPages(toRef(true));

  const similarPages = ref<Page[]>([]);
  const listLoading = ref<boolean>(false);

  const filterPages = (
    sourceValue: SourceValue,
    replaceMode: ReplaceMode
  ): Page[] => {
    return pagesList.value.filter(page => {
      const pageContent = pagesContentData.value[page.id].pageContent;

      for (const cellId in pageContent) {
        const widgets = pageContent[cellId].widgets;

        for (const widget of widgets) {
          const hasSimilarities = findSimilaritiesInContentWidget(
            sourceValue,
            widget,
            replaceMode
          );

          if (hasSimilarities) {
            return true;
          }
        }
      }

      return false;
    });
  };

  const generateCss = async (data: {
    grid: Grid;
    pageData: PageMeta;
    pageContentData: IGridWidgetsData;
    cssFileId: string;
    cellsOptions: Record<CellId, ICellOptions>;
  }) => {
    const { grid, pageData, pageContentData, cssFileId, cellsOptions } = data;

    try {
      const res = gridStore.generateWidgetsGridCss({
        gridLayout: grid,
        page: pageData,
        widgetsGridData: pageContentData,
        cssFileId,
        cellsOptions,
      });

      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject();
    }
  };

  const applyChangesToAllPages = async (
    sourceValue: SourceValue,
    pages: Page[],
    replaceMode: ReplaceMode
  ) => {
    for await (const page of pages) {
      const pageContent = pagesContentData.value[page.id];
      const pageData = pagesData.value[page.id];
      const templateData = templatesData.value[pageData.template];
      const cellsGrid = templateData.grid;

      const cssFileId = nanoid();
      const currentPageContent = deepCopy(pageContent.pageContent);

      for (const cellId in currentPageContent) {
        const cellWidgets = currentPageContent[cellId].widgets;

        currentPageContent[cellId].widgets = cellWidgets.map(widget => {
          return applyChangesToContentWidget(sourceValue, widget, replaceMode);
        });
      }

      const pageContentData: IGridWidgetsData = {
        templateId: pageData.template,
        pageContent: currentPageContent,
      };

      try {
        const gridCss = await generateCss({
          grid: cellsGrid,
          pageData: pageData,
          pageContentData: pageContentData,
          cssFileId,
          cellsOptions: pageContent.options.cellsOptions,
        });

        await gridStore.saveContentPage({
          pageId: page.id,
          widgetsData: pageContentData,
          options: pageContent.options,
          cellsOptions: pageContent.options.cellsOptions,
          cssFileId: cssFileId,
        });

        await gridStore.saveWidgetsGridCss(gridCss);

        await new Promise(resolve => {
          /*
            Wait 7sec after page save
          */
          setTimeout(() => {
            resolve(true);
          }, 7000);
        });
      } catch (e) {
        const currPage = pagesList.value.find(
          pageValue => pageValue.id === pageData.id
        );

        pagesWithErrors.value[pageData.id] = currPage!;
      }
    }
  };

  const findAllPagesSimilarities = async (
    sourceValue: SourceValue,
    replaceMode: ReplaceMode
  ): Promise<Page[]> => {
    listLoading.value = true;

    await fetchAllPages();
    await fetchPagesContent();
    await fetchTemplatesData();

    similarPages.value = filterPages(sourceValue, replaceMode).map(page => {
      return {
        ...page,
        key: page.id,
      };
    });
    listLoading.value = false;

    return similarPages.value;
  };

  return {
    findAllPagesSimilarities,
    applyChangesToAllPages,

    similarPages,
    listLoading,
    pagesWithErrors,
  };
};
