<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
      >
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgeDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #header-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>

        <template #title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #live_button_title-design>
          <ControlsList
            :widget="widget"
            :controls="headerButtonDesignControls"
          />
        </template>

        <template #live_button_title-content>
          <ControlsList
            :widget="widget"
            :controls="headerButtonContentControls"
          />
        </template>

        <template #live_button_title-states>
          <ControlsList
            :widget="widget"
            :controls="headerButtonStatesControls"
          />
        </template>

        <template #winner_league_title>
          <ControlsList
            :widget="widget"
            :controls="titleLeagueControls"
          />
        </template>

        <template #search_title>
          <ControlsList
            :widget="widget"
            :controls="searchTitleControls"
          />
        </template>

        <template #search_box-design>
          <ControlsList
            :widget="widget"
            :controls="searchBoxDesignControls"
          />
        </template>

        <template #search_box-content>
          <ControlsList
            :widget="widget"
            :controls="searchBoxContentControls"
          />
        </template>

        <template #winner_league_card-design>
          <ControlsList
            :widget="widget"
            :controls="leagueWinnerCardDesignControls"
          />
        </template>

        <template #winner_league_card-content>
          <ControlsList
            :widget="widget"
            :controls="leagueWinnerCardContentControls"
          />
        </template>

        <template #winner_league_bet_items-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #winner_league_bet_items-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #winner_league_bet_items-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #list_title>
          <ControlsList
            :widget="widget"
            :controls="titleListControls"
          />
        </template>

        <template #filter-design>
          <ControlsList
            :widget="widget"
            :controls="eventsFilterSettingsDesignControls"
          />
        </template>

        <template #filter-content>
          <ControlsList
            :widget="widget"
            :controls="eventsFilterSettingsContentControls"
          />
        </template>

        <template #filter-styles>
          <ControlsList
            :widget="widget"
            :controls="eventsFilterSettingsStyleControls"
          />
        </template>

        <template #list_container-design>
          <ControlsList
            :widget="widget"
            :controls="eventCardsDesignControls"
          />
        </template>

        <template #list_container-content>
          <ControlsList
            :widget="widget"
            :controls="eventCardsContentControls"
          />
        </template>

        <template #list_container-states>
          <ControlsList
            :widget="widget"
            :controls="eventCardsStatesControls"
          />
        </template>

        <template #top_leagues_list_container-design>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventCardsDesignControls"
          />
        </template>

        <template #top_leagues_list_container-content>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventCardsContentControls"
          />
        </template>

        <template #top_leagues_list_container-states>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventCardsStatesControls"
          />
        </template>

        <!--
            Start time/date
         -->
        <template #list_start_time>
          <ControlsList
            :widget="widget"
            :controls="timeDateControls"
          />
        </template>

        <template #top_leagues_list_start_time>
          <ControlsList
            :widget="widget"
            :controls="timeDateControls"
          />
        </template>

        <!--
            League title
         -->

        <template #league_title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #top_leagues_league_title>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <!--
            League team / player
         -->

        <template #list_event_name>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <template #top_leagues_list_event_name>
          <ControlsList
            :widget="widget"
            :controls="titleControls"
          />
        </template>

        <!--
            List bet item
         -->

        <template #list_bet_item-design>
          <ControlsList
            :widget="widget"
            :controls="betItemsDesignControls"
          />
        </template>

        <template #list_bet_item-content>
          <ControlsList
            :widget="widget"
            :controls="betItemsContentControls"
          />
        </template>

        <template #list_bet_item-states>
          <ControlsList
            :widget="widget"
            :controls="betItemsStateControls"
          />
        </template>

        <template #top_leagues_list_bet_item-design>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesBetItemsDesignControls"
          />
        </template>

        <template #top_leagues_list_bet_item-content>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesBetItemsContentControls"
          />
        </template>

        <template #top_leagues_list_bet_item-states>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesBetItemsStatesControls"
          />
        </template>
        <!--
          Event button link
         -->

        <template #list_item_more-design>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkDesignControls"
          />
        </template>

        <template #list_item_more-content>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkContentControls"
          />
        </template>

        <template #list_item_more-states>
          <ControlsList
            :widget="widget"
            :controls="eventButtonLinkStateControls"
          />
        </template>

        <template #top_leagues_list_item_more-design>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventButtonLinkDesignControls"
          />
        </template>

        <template #top_leagues_list_item_more-content>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventButtonLinkContentControls"
          />
        </template>

        <template #top_leagues_list_item_more-states>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventButtonLinkStateControls"
          />
        </template>

        <template #date_from>
          <ControlsList
            :widget="widget"
            :controls="filterLabelControls"
          />
        </template>

        <template #date_to>
          <ControlsList
            :widget="widget"
            :controls="filterLabelControls"
          />
        </template>

        <template #markets>
          <ControlsList
            :widget="widget"
            :controls="filterLabelControls"
          />
        </template>

        <template #betbuilder_button-design>
          <ControlsList
            :widget="widget"
            :controls="buildButtonDesignControls"
          />
        </template>

        <template #betbuilder_button-content>
          <ControlsList
            :widget="widget"
            :controls="buildButtonContentControls"
          />
        </template>

        <template #betbuilder_button-states>
          <ControlsList
            :widget="widget"
            :controls="buildButtonStateControls"
          />
        </template>

        <template #top_leagues_betbuilder_button-design>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesBuildButtonDesignControls"
          />
        </template>

        <template #top_leagues_betbuilder_button-content>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesBuildButtonContentControls"
          />
        </template>

        <template #top_leagues_betbuilder_button-states>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesBuildButtonStateControls"
          />
        </template>

        <!--
          Form element styles
         -->
        <template
          v-for="(controlValues, controlOptionName) in formStylesControls"
          #[controlOptionName]
          :key="controlOptionName"
        >
          <ControlsList
            :widget="widget"
            :controls="controlValues"
          />
        </template>

        <!-- 
          New
         -->

        <template #leagues_container-design>
          <ControlsList
            :widget="widget"
            :controls="leaguesContainerDesignControls"
          />
        </template>

        <template #leagues_container-content>
          <ControlsList
            :widget="widget"
            :controls="leaguesContainerContentControls"
          />
        </template>

        <!-- 
          League card
         -->

        <template #league_card-design>
          <ControlsList
            :widget="widget"
            :controls="leagueButtonDesignControls"
          />
        </template>

        <template #league_card-content>
          <ControlsList
            :widget="widget"
            :controls="leagueButtonContentControls"
          />
        </template>

        <template #league_card-states>
          <ControlsList
            :widget="widget"
            :controls="leagueButtonStateControls"
          />
        </template>

        <!-- 
          Tabs
         -->
        <template #items-design>
          <ControlsList
            :widget="widget"
            :controls="tabsDesignControls"
          />
        </template>

        <template #items-content>
          <ControlsList
            :widget="widget"
            :controls="tabsContentControls"
          />
        </template>

        <template #itemsGroup-design>
          <ControlsList
            :widget="widget"
            :controls="tabItemsDesignControls"
          />
        </template>

        <template #itemsGroup-content>
          <ControlsList
            :widget="widget"
            :controls="tabItemsContentControls"
          />
        </template>

        <template #itemsGroup-states>
          <ControlsList
            :widget="widget"
            :controls="tabItemsStatesControls"
          />
        </template>

        <template #events>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <template #topLeagues>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <template #allLeagues>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <template #leagueWinner>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <!-- Switch -->
        <template #switch-design>
          <ControlsList
            :widget="widget"
            :controls="switchDesignControls"
          />
        </template>

        <template #switch-content>
          <ControlsList
            :widget="widget"
            :controls="switchContentControls"
          />
        </template>

        <template #switchItemsGroup-design>
          <ControlsList
            :widget="widget"
            :controls="switchItemsDesignControls"
          />
        </template>

        <template #switchItemsGroup-content>
          <ControlsList
            :widget="widget"
            :controls="switchItemsContentControls"
          />
        </template>

        <template #switchItemsGroup-states>
          <ControlsList
            :widget="widget"
            :controls="switchItemsStatesControls"
          />
        </template>

        <template #byLeague>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>

        <template #byDate>
          <ControlsList
            :widget="widget"
            :controls="itemControls"
          />
        </template>
        <!-- 
          Filter

         -->

        <template #filter_container-design>
          <ControlsList
            :widget="widget"
            :controls="filterContainerDesignControls"
          />
        </template>

        <template #filter_container-content>
          <ControlsList
            :widget="widget"
            :controls="filterContainerContentControls"
          />
        </template>

        <!-- 
            Winner container
         -->

        <template #winner_container-design>
          <ControlsList
            :widget="widget"
            :controls="winnerContainerDesignControls"
          />
        </template>

        <template #winner_container-content>
          <ControlsList
            :widget="widget"
            :controls="winnerContainerContentControls"
          />
        </template>

        <!-- 
            Events container
         -->

        <template #events_container-design>
          <ControlsList
            :widget="widget"
            :controls="eventsContainerDesignControls"
          />
        </template>

        <template #events_container-content>
          <ControlsList
            :widget="widget"
            :controls="eventsContainerContentControls"
          />
        </template>

        <!-- 
            Top Leagues Events container
         -->

        <template #top_leagues_events_container-design>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventsContainerDesignControls"
          />
        </template>

        <template #top_leagues_events_container-content>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventsContainerContentControls"
          />
        </template>

        <!-- 
          Date
         -->
        <template #day_label-design>
          <ControlsList
            :widget="widget"
            :controls="dayLabelDesignControls"
          />
        </template>

        <template #day_label-content>
          <ControlsList
            :widget="widget"
            :controls="dayLabelContentControls"
          />
        </template>

        <template #top_leagues_day_label-design>
          <ControlsList
            :widget="widget"
            :controls="dayLabelDesignControls"
          />
        </template>

        <template #top_leagues_day_label-content>
          <ControlsList
            :widget="widget"
            :controls="dayLabelContentControls"
          />
        </template>

        <!-- 
          League
         -->
        <template #league_name-design>
          <ControlsList
            :widget="widget"
            :controls="dayLabelDesignControls"
          />
        </template>

        <template #league_name-content>
          <ControlsList
            :widget="widget"
            :controls="dayLabelContentControls"
          />
        </template>

        <template #top_leagues_league_name-design>
          <ControlsList
            :widget="widget"
            :controls="dayLabelDesignControls"
          />
        </template>

        <template #top_leagues_league_name-content>
          <ControlsList
            :widget="widget"
            :controls="dayLabelContentControls"
          />
        </template>

        <!-- 
          Event info
         -->
        <template #list_event_info-design>
          <ControlsList
            :widget="widget"
            :controls="eventInfoDesignControls"
          />
        </template>

        <template #list_event_info-content>
          <ControlsList
            :widget="widget"
            :controls="eventInfoContentControls"
          />
        </template>

        <template #top_leagues_list_event_info-design>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventInfoDesignControls"
          />
        </template>

        <template #top_leagues_list_event_info-content>
          <ControlsList
            :widget="widget"
            :controls="topLeaguesEventInfoContentControls"
          />
        </template>

        <!-- 
          Event info
         -->
        <template #list_date>
          <ControlsList
            :widget="widget"
            :controls="timeDateControls"
          />
        </template>

        <template #top_leagues_list_date>
          <ControlsList
            :widget="widget"
            :controls="timeDateControls"
          />
        </template>

        <!-- 
          Favorite
         -->
        <template #list_favourite_icon>
          <ControlsList
            :widget="widget"
            :controls="favoriteIconControls"
          />
        </template>

        <template #top_leagues_list_favourite_icon>
          <ControlsList
            :widget="widget"
            :controls="favoriteIconControls"
          />
        </template>

        <!-- 
          Search
         -->
        <template #search_container-design>
          <ControlsList
            :widget="widget"
            :controls="searchContainerDesignControls"
          />
        </template>

        <template #search_container-content>
          <ControlsList
            :widget="widget"
            :controls="searchContainerContentControls"
          />
        </template>

        <template #search_field-design>
          <ControlsList
            :widget="widget"
            :controls="searchFieldDesignControls"
          />
        </template>

        <template #search_field-content>
          <ControlsList
            :widget="widget"
            :controls="searchFieldContentControls"
          />
        </template>

        <template #search_field-states>
          <ControlsList
            :widget="widget"
            :controls="searchFieldStatesControls"
          />
        </template>

        <template #search_result_items-design>
          <ControlsList
            :widget="widget"
            :controls="searchResultItemsDesignControls"
          />
        </template>

        <template #search_result_items-content>
          <ControlsList
            :widget="widget"
            :controls="searchResultItemsContentControls"
          />
        </template>

        <template #search_result_items-states>
          <ControlsList
            :widget="widget"
            :controls="searchResultItemsStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useSportsbookConfig } from "~~/composables/widgets/sportsbook-fenix-mobile/useSportsbookConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "pageType": {
      label: "Page type",
      description:
        "Possible values: Sport | Country | League | Top leagues | All leagues",
      sources: ["custom"],
      required: true,
    },
    "verticalLayout": {
      label: "Use bet items vertical layout for more than 3 outcomes",
      description: "Possible values: yes | no. Empty === no",
      sources: ["custom"],
      required: false,
    },
    "leagueId": {
      label: "League ID",
      sources: ["pageRoute", "custom"],
      required: false,
    },
    "sportId": {
      label: "Sport ID",
      sources: ["pageRoute", "custom"],
      required: false,
    },
    "countryId": {
      label: "Country ID",
      sources: ["pageRoute", "custom"],
      required: false,
    },
    "leaguePageUrl": {
      label: "League page URL",
      sources: ["custom"],
      required: false,
    },
    "liveEventPageURL": {
      label: "Live event page URL",
      sources: ["custom"],
      required: false,
    },
    "eventPageURL": {
      label: "Event page URL",
      sources: ["custom"],
      required: false,
    },
    "eventsPageUrl": {
      label: "Events page URL",
      sources: ["custom"],
      required: false,
    },
    "topLeaguesPageUrl": {
      label: "Top leagues page URL",
      sources: ["custom"],
      required: false,
    },
    "allLeaguesPageUrl": {
      label: "All leagues page URL",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,
  widgeDesignControls,
  widgetContentControls,

  headerDesignControls,
  headerContentControls,

  titleControls,
  titleLeagueControls,
  searchTitleControls,
  titleListControls,

  headerButtonStatesControls,
  headerButtonDesignControls,
  headerButtonContentControls,

  leagueWinnerCardDesignControls,
  leagueWinnerCardContentControls,

  betItemsDesignControls,
  betItemsContentControls,
  betItemsStateControls,

  eventsFilterSettingsDesignControls,
  eventsFilterSettingsContentControls,
  eventsFilterSettingsStyleControls,

  eventCardsDesignControls,
  eventCardsContentControls,
  eventCardsStatesControls,

  eventButtonLinkDesignControls,
  eventButtonLinkContentControls,
  eventButtonLinkStateControls,

  buildButtonDesignControls,
  buildButtonContentControls,
  buildButtonStateControls,

  filterLabelControls,

  formStylesControls,

  leaguesContainerDesignControls,
  leaguesContainerContentControls,

  leagueButtonStateControls,
  leagueButtonDesignControls,
  leagueButtonContentControls,

  filterContainerDesignControls,
  filterContainerContentControls,

  winnerContainerDesignControls,
  winnerContainerContentControls,

  eventsContainerDesignControls,
  eventsContainerContentControls,

  eventInfoDesignControls,
  eventInfoContentControls,

  timeDateControls,
  favoriteIconControls,

  tabsDesignControls,
  tabsContentControls,
  tabItemsDesignControls,
  tabItemsContentControls,
  tabItemsStatesControls,

  itemControls,

  searchFieldDesignControls,
  searchFieldContentControls,
  searchFieldStatesControls,

  searchContainerDesignControls,
  searchContainerContentControls,

  searchResultItemsDesignControls,
  searchResultItemsContentControls,
  searchResultItemsStatesControls,

  searchBoxContentControls,
  searchBoxDesignControls,

  topLeaguesEventsContainerDesignControls,
  topLeaguesEventsContainerContentControls,

  topLeaguesEventCardsDesignControls,
  topLeaguesEventCardsContentControls,
  topLeaguesEventCardsStatesControls,

  topLeaguesEventButtonLinkDesignControls,
  topLeaguesEventButtonLinkContentControls,
  topLeaguesEventButtonLinkStateControls,

  topLeaguesBuildButtonStateControls,
  topLeaguesBuildButtonDesignControls,
  topLeaguesBuildButtonContentControls,

  topLeaguesBetItemsDesignControls,
  topLeaguesBetItemsContentControls,
  topLeaguesBetItemsStatesControls,

  topLeaguesEventInfoDesignControls,
  topLeaguesEventInfoContentControls,

  dayLabelDesignControls,
  dayLabelContentControls,

  switchDesignControls,
  switchContentControls,
  switchItemsDesignControls,
  switchItemsContentControls,
  switchItemsStatesControls,
} = useSportsbookConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
